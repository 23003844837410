import Vue from 'vue'
import Vuex from 'vuex'
// import conf from '../assets/conf/conf.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedBoxID:null,
    selectedDroneID:'null',
    // videoServer : null,
    // sioServer : null,
    sioServer : (window.location.hostname.includes('-prod'))?"https://dbx-prod.arv.co.th":"https://dbx-dev.arv.co.th",
    videoServer : "https://streaming.dronebox-prod.arv.co.th/WebRTCAppEE/play.html?name=",
    // sioServer : "https://dbx-dev.arv.co.th",
    // sioServer : "https://dbxio.dronebox-dev.arv.co.th",
    // apiDataServe: "/api",
    // webAPI : "/api",
    // apiDataServe: "https://webapp-dbx-dev.arv.co.th/api",// "http://ec2-18-141-181-249.ap-southeast-1.compute.amazonaws.com:6904",
    // webAPI : "https://webapp-dbx-dev.arv.co.th/api",
    apiDataServe: (window.location.hostname.includes('localhost'))?"https://webapp-dbx-dev.arv.co.th/api":"/api",
    webAPI : (window.location.hostname.includes('localhost'))?"https://webapp-dbx-dev.arv.co.th/api":"/api",
    apiKey : null,
    deviceKey : null,
    user:null,
    customerID : null,
    fullUserName : null,
    owner:null,
    site:null,
    siteLocation:[],
    boxLocation : [],
    isLogedin:false,
    isMntLogedin:false,
    assetInfo:[],
    assetBoxes : [],
    assetDrones : [],
    customerAsset : [],    
    selectedAsset : [],
    sessionEndURL : '/#/',
    userLevel:0,
    appName:'MyHorrusMnt',
    userInfo:{
      id:null,
      token:null,
      email:null,
      roles:null,
      name:null,
      cid:null
    }
  },
  mutations: {
    setUserInfo(state,info){
      state.userInfo = info
    },
    setDeviceKey(state,key){
      state.deviceKey = key
    },
    setAPIKey(state,key){
      state.apiKey = key
    },
    setcustomerID(state,cid){
      state.customerID = cid
    },
    setcustomerAsset(state,assets){
      state.customerAsset = assets
    },
    setselectedAsset(state,item){
      state.selectedAsset = item
    },
    setBoxID(state,boxid){
      state.selectedBoxID = boxid
    },
    setDroneID(state,droneid){
      state.selectedDroneID = droneid
    },
    setLoginState(state,bit){
      state.isLogedin = bit
    },
    setMntLoginState(state,bit){
      state.isMntLogedin = bit
    },
    setUser(state,userid){
      state.user = userid
    },
    setUserLevel(state,level){
      state.userLevel = level
    },
    setFullName(state,fullname){
      state.fullUserName = fullname
    },
    setOwner(state, company){
      state.owner = company
    },
    setSite(state, site){
      state.site = site
    },
    setSiteLocation(state, siteLocation){
      state.siteLocation = siteLocation
    },
    setBoxLocation(state, boxLocation){
      state.boxLocation = boxLocation
    },
    setAssetInfo(state, Asset){
      state.assetInfo.push(Asset)
    },
    setAssetBoxes(state, Box){
      state.assetBoxes.push(Box)
    },
    setAssetDrones(state, Drone){
      state.assetDrones.push(Drone)
    },
    setSioServer(state,key){
      state.sioServer = key
    },
    setVideoServer(state,key){
      state.videoServer = key
    },
    setSessionEndURL(state,url){
      state.sessionEndURL = url
    },
  },
  actions: {
  },
  modules: {
  },
  getters:{
    userInfo:state=>state.userInfo,
    selectedBoxID:state=>state.selectedBoxID,
    selectedDroneID:state=>state.selectedDroneID,
    apiDataServe:state=>state.apiDataServe,
    owner:state=>state.owner,
    webAPI:state=>state.webAPI,
    sioServer:state=>state.sioServer,
    apiKey:state=>state.apiKey,
    customerID:state=>state.customerID,
    user:state =>state.user,
    deviceKey:state => state.deviceKey,
    customerAsset:state => state.customerAsset,
    setselectedAsset:state => state.setselectedAsset,
    videoServer:state => state.videoServer,
    sessionEndURL:state => state.sessionEndURL,
    userLevel:state => state.userLevel,
  }
})
