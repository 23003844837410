<template>
  <div>
    <div class="main-content" style="background: var(--color-dark)">
      <header1/>
      <b-container v-for="(batteryData, index) in batteriesData" :key="'container'+index" fluid>
        <div class="grid-container2">
          <b-card title="Battery Health" class="grid-item">
            <b-card-text>
              <apexchart
                width="420"
                type="radialBar"
                :ref="'chart-health-'+index"
                :series="healthOptions.series"
                :options="healthOptions"
              ></apexchart>
            </b-card-text>
          </b-card>
          <b-card title="Charge Cycle" class="grid-item">
            <b-card-text>
              <apexchart
                width="420"
                type="radialBar"
                :ref="'chart-cycle'+index"
                :series="cycleOptions.series"
                :options="cycleOptions"
              ></apexchart>
            </b-card-text>
            <b-card-text>
              <h1>
                {{ batteryData.table.length }}
                <small> Times of {{ batteryData.info.Expire }}</small>
              </h1>
            </b-card-text>
          </b-card>
          <b-card title="Health Trend" class="grid-item">
            <b-card-text>
              <apexchart
                width="100%"
                type="line"
                :ref="'chart-'+index"
                :options="chartOptions"
                :series="batteryData.chart"
              ></apexchart>
            </b-card-text>
          </b-card>
        </div>
        <div class="grid-container">
          <b-card header="Battery Information">
            <b-card-text
              v-for="(value, key) in batteryData.info"
              :key="key + value"
            >
              <CardValue :name="key" :value="value"
            /></b-card-text>
          </b-card>
          <b-card>
            <b-card-text>
              <b-table
                striped
                hover
                :items="batteryData.table"
                :fields="fields"
                :busy="isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(stop_time)="data">
                  {{ ms2str(data.value)}}
                </template>
                <template #cell(health)="data">
                  {{ data.value.toFixed(2) + " %"}}
                </template></b-table
              ></b-card-text
            >
          </b-card>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import header1 from "../components/header/Header_Normal.vue";
import CardValue from "../components/CardValue.vue";
import swal from "sweetalert2";
export default {
  name: "Dashboard",
  components: {
    header1,
    CardValue,
  },
  props: {
    serverTime: Object,
  },
  data() {
    return {
      isBusy: true,
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "charge_cycle",
          label: "Cycle",
          sortable: true,
        },
        // {
        //   key: 'charge_id',
        //   label: 'Charge ID',
        //   sortable: false
        // },
        {
          key: "stop_time",
          label: "Date",
          sortable: true,
        },
        {
          key: "qcount",
          label: "Charge (Coulombs)",
          sortable: false,
        },
        {
          key: "charging_time",
          label: "Duration (Minutes)",
          sortable: true,
        },
        {
          key: "health",
          sortable: true,
        },
      ],
      pageRenderInfo: {
        deviceID: -1,
        deviceIndex: -1,
        siteIndex: -1,
        deviceKey: "",
      },
      droneID: null,
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      // selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      batteriesData:[],
      // batteryData: {
      //   chart: [],
      //   info: {},
      //   logs: [],
      //   table: [],
      // },
      chart: null,
      chartOptions: {
        chart: {
          height: 380,
          width: "100%",
          type: "line",
          animations: {
            initialAnimation: {
              enabled: true,
            },
          },
        },
        colors: ["#00BAEC"],
        stroke: {
          width: 2,
        },
        // grid: {
        //   borderColor: "#555",
        //   clipMarkers: false,
        //   yaxis: {
        //     lines: {
        //       show: false,
        //     },
        //   },
        // },
        dataLabels: {
          enabled: false,
        },
        // fill: {
        //   gradient: {
        //     enabled: true,
        //     opacityFrom: 0.55,
        //     opacityTo: 0,
        //   },
        // },
        // markers: {
        //   size: 5,
        //   colors: ["#000524"],
        //   strokeColor: "#00BAEC",
        //   strokeWidth: 3,
        // },
        tooltip: {
          theme: "dark",
        },
        xaxis: {
          type: "datetime",
        },
        annotations: {
          yaxis: [
            {
              y: 80,
              borderColor: "#e32500",
              label: {
                borderColor: "#e32500",
                style: {
                  color: "#fff",
                  background: "#e32500",
                },
                text: "Battery Health is not recommend to use at 80%",
              },
            },
          ],
        },
        yaxis: {
          show: true,
          showAlways: false,
          showForNullSeries: true,
          seriesName: undefined,
          opposite: false,
          reversed: false,
          logarithmic: false,
          logBase: 10,
          tickAmount: undefined,
          min: 60,
          max: 100,
          stepSize: 20,
          forceNiceScale: false,
          floating: false,
          decimalsInFloat: undefined,
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return value + "%";
            },
          },
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          crosshairs: {
            show: true,
            position: "back",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        },
      },
      series: [],
      //   {
      //     name: "Health",
      //     // data: [30, 40, 45, 50, 49, 60, 70, 81],
      //     data: [],
      //   },
      // ],
      healthChart: null,
      healthOptions: {
        chart: {
          height: 50,
          type: "radialBar",
        },

        series: [80],
        colors: ["#a73013"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                fontSize: "13px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#20E647"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Health"],
      },
      cycleOptions: {
        chart: {
          height: 50,
          type: "radialBar",
        },
        series: [67],
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: "#333",
              startAngle: -135,
              endAngle: 135,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            gradientToColors: ["#a73013"],
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "butt",
        },
        labels: ["Progress"],
      },
    };
  },
  beforeCreate() {},
  created() {
    let id = this.$route.params.droneID;
    let currentObj = this;
    console.log("ID: ", id);
    if (id) {
      console.log("ID: ", id);
      let key1 = this.getKey(id);
      // currentObj.pageRenderInfo.deviceKey = key1;
      console.log("Key : ", key1);
      currentObj.pageRenderInfo.deviceID = id;
      this.getIndex();
    } else {
      swal.fire({
        icon: "error",
        title: "Oops... ",
        text: "Something went wrong! Please try again later",
      });
    }
  },
  computed: {},
  mounted() {
    // this.chart = this.$refs["chart"];
    this.getBatteryData();
  },
  methods: {
    async getBatteryData() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url:
          this.webAPI + "/app/info/bid?drone_id=" + this.$route.params.droneID,
        headers: {},
      };
      let currentObj = this;
      await this.axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          // currentObj.batteryData = response.data;
          currentObj.batteriesData = response.data;
          currentObj.batteriesData.forEach((battery, index) => {
            currentObj.series.push({name: "Health", data:battery.chart}); //response.data.chart;
            this.$refs['chart-'+ index].updateSeries(
              [
                {
                  data: battery.chart,//this.series[0].data,
                },
              ],
              false,
              true
            );
            var health = battery.table[battery.table.length - 1].health;
            var cycle = ((battery.table.length / battery.info.Expire) * 100).toFixed(2);
            try{
              this.$refs["chart-health-"+index].updateSeries([health.toFixed(2)]);
            } catch {
            //
            }
            this.$refs["chart-cycle-"+index].updateSeries([cycle]);
          });
          currentObj.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ms2str(ms) {
      // Create a new Date object using the milliseconds
      const date = new Date(ms);

      // Get the date components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // Construct the datetime string
      const datetimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return datetimeString;
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.grid-container2 {
  display: grid;
  grid-template-columns: 32% 30% 34%;
  padding: 10px;
  margin: auto;
  column-gap: 2%;
}
.grid-container {
  display: grid;
  grid-template-columns: 32% 66%;
  column-gap: 2%;
  padding: 10px;
  text-align: start;
  margin: auto;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
h2 {
  font-size: 1.2em;
}
.card-data-value h4 {
  font-size: 0.95em;
  width: 95%;
  overflow: hidden;
  display: inline-block;
  /* text-align: center; */
}
.contentmain {
  margin-left: 2rem;
  margin-right: 2rem;
  height: calc(100vh - 100px);
}
.card-body {
  max-height: 70vh;
  overflow-y: auto;
}
main {
  margin-top: 0px;
  padding: 1.4rem 0rem 0rem 0rem;
  background: #f1f5f9;
  /* min-height: calc(100vh - 85px); */
}
.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  /* margin-top: 0rem; */
}
.card-single {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
  border-radius: 2px;
}
.card-single div:last-child span {
  color: var(--main-color);
  font-size: 3rem;
}
.card-single h1 {
  font-size: 1.2em;
}

.card-single div:first-child span {
  color: var(--text-grey);
  font-size: 00.8em;
}
.card-single:last-child {
  background: var(--main-color);
}
.card-single:last-child h1,
.card-single:last-child div:last-child span,
.card-single:last-child div:first-child span {
  color: #fff;
}
.recent-grid {
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 67% auto;
}
.card {
  background: #fff;
  border-radius: 5px;
}
.card-header,
.card-body {
  padding: 0.5rem 1rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 solid #f0f0f0;
  margin: 0px;
  min-height: 3.5rem;
}
.card-header button {
  background: var(--main-color);
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--main-color);
}
table {
  /* border-collapse: collapse;
   */
  position: relative;
  border-collapse: collapse;
}
thead th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}
thead tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
thead td {
  font-weight: 700;
}
td {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: #222;
}
tr td:last-child {
  display: flex;
  align-items: center;
}
td .status {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 1rem;
}
.red {
  background-color: red !important;
}
.yellow {
  background-color: yellow !important;
}
.green {
  background-color: green !important;
  color: white;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
}
.customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.7rem;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  border-radius: 50%;
  margin-right: 1rem;
}
.info h4 {
  font-size: 0.8rem;
  font-weight: 700;
  color: #222;
}
.info small {
  font-weight: 600;
  /* color: var(--text-grey); */
}
.contact span {
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--main-color);
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  /* height: 50px; */
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background: #f0f0f0;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5em;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section {
  width: calc(100vw - 350px - 80px - 4rem);
  /* width: 545px; */
  height: 700px;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: left;
  margin-left: 1rem;
  margin-right: 0px;
}

aside {
  width: 305px;
  height: 88vh;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: right;
  margin-left: 1rem;
  margin-right: 41px;
}
.clearfix {
  clear: both;
  margin-bottom: 20px;
}
footer {
  width: 880px;
  height: 50px;
  background: yellow;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.content1 {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  /* margin-bottom: 1rem; */
  border: #222 solid 1px;
  height: calc(50% - 1rem);
  /* width: 100%; */
}
.content1-upper {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  height: 50%;
  /* width: 100%; */
}
.main-container {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  position: relative;
  width: 100%;
  height: 88vh;
  display: block;
  position: relative;
  background: gray;
  overflow: hidden;
  /* width: 100%; */
}
.content1-upper h1,
.infor-status table,
.content1 h1 {
  font-size: 0.75rem;
}
.video-main {
  /* float: left; */
  width: 100%;
  /* width: calc(100vw - 350px - 80px - 4rem - 31%); */
  /* padding-right: 1rem; */
  /* height: calc(100vh - 72%); */
  height: 26.8vw;
  border-radius: 10px;
  overflow: hidden;
  max-height: 50vh;
  /* background-color: black; */
}
.map-panel {
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  /* height:300px; */
  height: 18.76vw;
}
.emergency-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn:hover {
  background-color: rgb(0, 255, 170);
  /* color: goldenrod; */
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.emergency-btn:hover {
  background-color: rgb(255, 0, 0);
  color: yellow;
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.log-msg {
  width: 100%;
  height: calc(100vh - 26.8vw - 130px);
  /* min-height: 350px;*/
  max-height: calc(100vh - 26.8vw - 130px);
  overflow: auto;
  /* overflow-y: auto; */
  /* background-color: var(--text-grey); */
  border-radius: 10px;
  font-size: 0.3rem;
}

.test {
  font-size: 0.5rem;
}
.control-status {
  min-height: 200px;
  max-height: 350px;
}
.device-status {
  float: left;
  width: calc(50% - 1rem);
  /* max-height: 250px;
  overflow-y: auto; */
}
.device-control {
  float: right;
  width: calc(50% - 1rem);
  height: 280px;
}
.tab-device-status {
  max-height: 250px;
  overflow-y: auto;
}
.cmd-btn {
  height: 50px;
  width: 150px;
  margin: 5px;
  padding: 5px;
  font-size: 0.75rem;
  border-radius: 10px;
  border-width: 1px;
}
.cmd-btn:hover {
  background-color: lightseagreen;
}
b-tab {
  font-size: 0.75rem;
}
.tab-class {
  font-size: 0.5rem;
}
.nav-tab-calss {
  font-size: 0.5rem;
}
.content-operation {
  width: 100%;
  height: 90vh;
  display: block;
  position: relative;
  background: gray;
}

.arm-status {
  text-shadow: 2px 2px #000000;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 auto;
  padding: 0 auto;
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 100;
}
.miniMap {
  transition: top 300ms;
  border-radius: 200px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  /* background-color: rgba(22, 22, 22, 0.8); */
  width: 400px;
  height: 400px;
  z-index: 1;
  overflow: hidden;
  opacity: 0.3;
}
.zoombtn {
  position: absolute;
  left: 270px;
  bottom: 190px;
  z-index: 2;
  width: 140px;
}
.alarm {
  background-color: rgba(255, 0, 0, 0.8);
  /* color: yellow; */
}
.paraView {
  position: absolute;
  top: 500px;
  right: 10px;
  margin: 1rem;
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.6); */
  color: #f2f2f2;
  font-size: 1.8em;
  z-index: 100;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.status-card {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.infor-view {
  position: absolute;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  /* margin: 1rem; */
  align-content: flex-start;
  background: rgba(60, 60, 60, 0.74);
  color: #f2f2f2;
  /* font-size: 1.8em; */
  z-index: 100;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-bottom: 10pex;
}
.icon-wrapper span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.icon-wrapper h4 {
  margin-bottom: -2px;
}
.icon-wrapper small {
  display: inline-block;
  /* color: var(--text-grey); */
  margin-top: -10rem !important;
}
.control-command {
  /* position: absolute;
  bottom: 1rem;
  right: 1rem; */
  /* margin: 1rem; */
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.74); */
  color: #f2f2f2;
  /* font-size: 1.8em; */
}
.test1 {
  /* margin: 2px; */
  margin: 0px;
  padding: 0px;
}
.left1-container {
  margin-top: 10px;
}
.center1-container {
  margin-top: 10px;
}

.card-data-value {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
}
.card-data-value2 {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  text-align: center;
}
.card-data {
  margin: 2px;
  padding: 20px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
.card-frame {
  /* margin: 2px; */
  /* padding: 20px; */
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
/* @-webkit-keyframes alarm { */
@keyframes alarm {
  0%,
  49% {
    background-color: whitesmoke;
    /* border: 1px solid red; */
  }
  50%,
  100% {
    background-color: yellow;

    /* border: 1px solid red; */
  }
}
.statusMini {
  max-width: 25vw;
}
.valueMaxCard {
  max-width: 100% !important;
  display: block;
}
.valveMinCard {
  max-width: 25% !important;
  display: inline;
}
.value-moving {
  /* margin: 2px; */
  /* background-color: yellow; */
  color: red;
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: alarm 1s infinite; /* Safari 4+ */
  -moz-animation: alarm 1s infinite; /* Fx 5+ */
  -o-animation: alarm 1s infinite; /* Opera 12+ */
  animation: alarm 1s infinite; /* IE 10+, Fx 29+ */
}
.uavControlPanel {
  font-size: 0.75rem;
  margin: 0px;
  padding: 0px;
  height: 35vh;
}
@media only screen and (max-width: 560px) {
  .map-panel {
    height: 25vh !important;
    /* display: none; */
  }
  .video-main {
    height: 25vh !important;
    /* display: none; */
  }
  .statusMini {
    max-width: 100vw !important;
  }
  .card-frame {
    max-height: auto;
    height: auto !important;
  }
  .uavControlPanel {
    height: auto !important;
  }
  .card-data,
  .card-body {
    max-height: 100% !important;
    height: auto !important;
  }
}

h3 {
  width: 100%;
  font-size: 1em;
  text-align: center;
}
.card-data-value-test {
  font-size: 0.75em;
}
.zoomControlBtn {
  display: block;
  width: 100%;
  margin: 10px 0 10px 0;
  font-size: 2em;
}
.menualPalyloadBtn {
  display: block;
  width: 40%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn2 {
  display: block;
  width: 25%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn3 {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* font-size: 2em; */
}
.tranfrom45 {
  transform: rotate(45deg);
}
.tranfrom-45 {
  transform: rotate(-45deg);
}
.noMargin {
  margin: 0 !important;
  width: 50%;
}
.shutterControlBtn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 5px;
}
.shutterStopControlBtn {
  width: 70px;
  height: 70px;
  /* border-radius: 50%; */
}
.btn-layout {
  display: grid;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
  width: 100%;
}
.btn-mode {
  /* width: 100%; */
  height: 70px;
  padding: 20px;
  font-size: 12px;
  text-align: center;
}
</style>
