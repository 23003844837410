
<template>
  <div id="app" ref="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

    <Loginpage v-if="!$store.state.isLogedin && !($route.path.includes('/mnt'))" />
    <mntlogin v-if="!$store.state.isMntLogedin && ($route.path.includes('/mnt'))"></mntlogin>
    <!-- <MainMenu v-if="$store.state.isLogedin"/> -->
    <!-- <NavBar :organizationName="organizationName" :userName="userName" :userTitle="userTitle"/> -->
    <!-- <mntLogin></mntLogin> -->
    <menubar
      :notifyCount="notifications.length"
      class="mainnav"
      v-if="$store.state.isLogedin"
    ></menubar>

    <menubarbof v-if="$store.state.isMntLogedin"
      :notifyCount="notifications.length"
      class="mainnav"></menubarbof>


    <div class="main-content" v-if="$store.state.isLogedin && !($route.path.includes('/mnt'))">
      <router-view class="content-space" :serverTime="serverTime" />
      <!-- <div class="footer-space"></div> -->
    </div>
    <div class="main-content" v-if="$store.state.isMntLogedin && ($route.path.includes('/mnt'))">
      <router-view class="content-space" :serverTime="serverTime" />
      <!-- <div class="footer-space"></div> -->
    </div>
    <menubar2 class="nav-mobile" v-if="$store.state.isLogedin" />
    <b-modal
      id="Notifications-modal"
      size="lg"
      scrollable
      title="Notifications"
      hide-footer
    >
      <!-- <p class="my-4" v-for="i in 20" :key="i">
      Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
      in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
    </p> -->
      <notify :hirItem="item" @close-modal="$bvModal.hide('Notifications-modal')" v-for="item in notifications" :key="item.taskID" />
      <!-- <b-button class="mt-3" block @click="$bvModal.hide('Notifications-modal')">Close Me</b-button> -->
    </b-modal>
  </div>
</template>
<script>
// @ is an alias to /src
// import MainMenu from '@/components/NavigateManu.vue'
import Loginpage from "./views/Login.vue";
import menubar from "@/components/navs/NavBar";
import menubarbof from "@/components/navs/NavBarBOF";
import menubar2 from "@/components/navs/NavBarMobile";
import io from "socket.io-client";
import notify from "./components/notifications/Notify.vue";
import mntlogin from "./views/backoffice/Login.vue"
// import NavBar from './components/v3/navigationBar.vue'
export default {
  name: "Home",
  components: {
    menubar2,
    Loginpage,
    menubar,
    notify,
    mntlogin,
    menubarbof,
    // NavBar
  },
  data() {
    return {
      battState: 0,
      // name: "Device Name",
      // isLoggedin: this.$store.state.isLogedin,
      serverTime: { date: null, time: null },
      intervalSession: null,
      intervalSessionStatus: null,
      organizationName: "HORRUS Interface",
      // userName: "Komet Kongtandee",
      userTitle: "Engineer",
      // apiKey: null,
      taskIO: null,
      customerKey:null,
      notifications: [
        // {
        //   msgFrom: 21,
        //   msgTo: "UI",
        //   taskID: 1990,
        //   takeoffStationID: 21,
        //   droneID: 22,
        //   landingStationID: 32,
        //   request: {
        //     command: null,
        //     params: null,
        //     message: "test MSG #Weather Concern at Station 253#OMG #3322",
        //   },
        // },
      ],
    };
  },

  computed: {
    loginState() {
      return this.$store.state.isLogedin;
    },
    webAPI() {
      return this.$store.state.webAPI;
    },
    apiKey() {
      return this.$store.state.apiKey;
    },
    sioServer() {
      return this.$store.state.sioServer;
    },
    customerID() {
      return this.$store.state.customerID;
    },
    userName() {
      return this.$store.state.user;
    },
    customerAssets(){ return this.$store.state.customerAsset;}
  },
  watch: {
    loginState(newValue, oldValue) {
      // Do something with the new value of my_state
      console.log(`loginState changed from ${oldValue} to ${newValue}`);
      this.getKey(this.customerID);
      if (newValue == true) {
        this.taskIO = io(this.sioServer + "/task", {
          auth: { deviceID: this.userName, token: this.apiKey },
          transports: ["websocket"],
        });
        this.taskIO.on("connect", this.onConnect);
        this.taskIO.on("hir_req", (data) => this.handleNotify(data));
        this.taskIO.on("hir_sync", (data) => this.handleHIRSync(data));
        this.taskIO.on("hir_cancel", (data) => this.dropNotify(data));

      } else {
        this.taskIO.disconnect();
      }
    },
  },
  created() {
    this.getConfig();
    console.log("Page Loaded......... External!!!");
    let apiKey = null;
    let endSessionEndpoint = null;
    let queryString = window.location.search;
    console.log(queryString);
    let urlParams = new URLSearchParams(queryString);
    console.log(urlParams);
    if (urlParams.has("apiKey")) {
      apiKey = urlParams.get("apiKey");
    }
    if (urlParams.has("origin")) {
      endSessionEndpoint = urlParams.get("origin");
    }
    console.log("API Key : ", apiKey);
    console.log("Endpoint : ", endSessionEndpoint);
    if (!(apiKey === null && endSessionEndpoint === null)) {
      console.log("Found API key and origin");
      sessionStorage.setItem("setLoginState", true);
      sessionStorage.setItem("setAPIKey", apiKey);
      sessionStorage.setItem("setSessionEndURL", endSessionEndpoint);
      this.$store.commit("setAPIKey", apiKey);
      this.$store.commit("setSessionEndURL", endSessionEndpoint);
      this.apiKey = apiKey;
    }
  },
  mounted() {
    // this.checkSession();
    // this.intervalSession = setInterval(this.checkSession, 5000);
    this.checkSessionStatus();
    this.intervalSessionStatus = setInterval(this.checkSessionStatus, 5000);
  },
  methods: {
    async handleNotify(data){
      let currentObj = this;
      console.log("Notify Added Call");
      console.log(data);
      currentObj.notifications.unshift(data)
    },
    async getConfig() {
      // let webAPI = this.$store.state.webAPI;
      var res_data = null;
      await this.axios.get(this.webAPI + "/endpoint").then(function (response) {
        console.log("res_code : ", response.data);
        res_data = response.data;
      })
        .catch(e => console.error(e));
      sessionStorage.setItem("setSioServer", res_data.SIO_SERVER);
      sessionStorage.setItem("setVideoServer", res_data.STREAMING_SERVER);
      this.$store.commit("setSioServer", res_data.SIO_SERVER);
      this.$store.commit("setVideoServer", res_data.STREAMING_SERVER);
    },
    checkSession() {
      var sio = this.sioServer;
      let sessionEndURL = this.$store.state.sessionEndURL;
      var streaming = this.$store.state.videoServer;
      if (sio == null || streaming == null) this.getConfig();
      var LoginState = sessionStorage.getItem("setLoginState");
      if (LoginState === "true") {
        this.$store.commit("setLoginState", true);
        var setUser = sessionStorage.getItem("setUser");
        this.$store.commit("setUser", setUser);
        var setFullName = sessionStorage.getItem("setFullName");
        this.$store.commit("setFullName", setFullName);
        var setOwner = sessionStorage.getItem("setOwner");
        this.$store.commit("setOwner", setOwner);
        var setAPI_Key = sessionStorage.getItem("setAPIKey");
        this.$store.commit("setAPIKey", setAPI_Key);
        var setcustomerID = sessionStorage.getItem("setcustomerID");
        this.$store.commit("setcustomerID", setcustomerID);

        let url = sessionStorage.getItem("setSessionEndURL");
        this.$store.commit("setSessionEndURL", url);
        // var setAssetInfo = sessionStorage.getItem("setAssetInfo");
        // this.$store.commit("setAssetInfo", setAssetInfo);
      } else {
        this.$store.commit("setLoginState", false);
        sessionStorage.setItem("setLoginState", false);
        // window.location.href = "/#/";
        // window.location.href = sessionEndURL;
        window.location.replace(sessionEndURL);
      }
    },
    async checkSessionStatus() {
      let sessionEndURL = sessionStorage.getItem("setSessionEndURL");
      // let sessionEndURL = this.$store.state.sessionEndURL;
      let webAPI = this.$store.state.webAPI;
      let apiKey = this.$store.state.apiKey;
      if (apiKey == null) {
        apiKey = sessionStorage.getItem("setLoginState");
        this.$store.commit("setAPIKey", apiKey);
      }
      let LoginState = sessionStorage.getItem("setLoginState");
      if (LoginState === "true") {
        var setUser = sessionStorage.getItem("setUser");
        if (setUser == null) {
          let config_info = {
            method: "get",
            maxBodyLength: Infinity,
            url: webAPI + "/info/api/user",
            headers: {
              authorization: apiKey,
            },
          };

          await this.axios
            .request(config_info)
            .then((response) => {
              console.log(JSON.stringify(response.data));

              sessionStorage.setItem("setUser", response.data.uname);
              sessionStorage.setItem("setcustomerID", response.data.cid);
              this.$store.commit("setUser", response.data.uname);
              this.$store.commit("setcustomerID", response.data.cid);
              let url = sessionStorage.getItem("setSessionEndURL");
              this.$store.commit("setSessionEndURL", url);
              this.$store.commit("setUserLevel", response.data.level);
              sessionStorage.setItem("setUserLevel", response.data.level);
              this.$forceUpdate();
              // this.$router.push({ path: "home" });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$store.commit("setUser", setUser);
          var setFullName = sessionStorage.getItem("setFullName");
          this.$store.commit("setFullName", setFullName);
          var setOwner = sessionStorage.getItem("setOwner");
          this.$store.commit("setOwner", setOwner);
          var setAPI_Key = sessionStorage.getItem("setAPIKey");
          this.$store.commit("setAPIKey", setAPI_Key);
          var setcustomerID = sessionStorage.getItem("setcustomerID");
          this.$store.commit("setcustomerID", setcustomerID);
          var setUserLevel = sessionStorage.getItem("setUserLevel");
          this.$store.commit("setUserLevel", setUserLevel);
          let url = sessionStorage.getItem("setSessionEndURL");
          this.$store.commit("setSessionEndURL", url);
        }
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: webAPI + "/sessionStatus",
          headers: { authorization: apiKey },
        };

        await this.axios
          .request(config)
          .then(() => {
          // .then((response) => {
            // console.log(JSON.stringify(response.data));
            this.$store.commit("setLoginState", true);
            sessionStorage.setItem("setLoginState", true);
            // console.log(
            //   "[checkSessionStatus_OK] Redirect to origin....(",
            //   sessionEndURL,
            //   ")"
            // );
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit("setLoginState", false);
            sessionStorage.setItem("setLoginState", false);
            // console.log(
            //   "[checkSessionStatus] Redirect to origin....(",
            //   sessionEndURL,
            //   ")"
            // );
            // deepcode ignore OR: <please specify a reason of ignoring this>
            window.location.href = sessionEndURL;
          });
      }
    },
    async getKey(id) {
      let currentObj = this;
      var customerKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          customerKey = response.data.key;
          console.log(customerKey);
          currentObj.customerKey = customerKey;
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return customerKey;
    },
    async onConnect() {
      let currentObj = this;
      console.log("TaskIO Call Connect")
      console.log("CID_Key:", currentObj.customerKey);
      if (this.taskIO.connected) {
        console.log("Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.taskIO.emit("join", {
          token: this.apiKey,
          room: currentObj.customerKey,
        });
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.taskIO.emit("hir_sync", {
          token: this.apiKey,
          deviceID: this.userName,
          data:{cid: currentObj.customerID,}
        });
      }
    },
    handleHIRSync(data){
      let currentObj = this;
      console.log(data);
      currentObj.notifications = [];
      currentObj.notifications = data;
    },
    dropNotify(data) {
      let currentObj = this;
      console.log("Drop Notification");
      console.log(data);
      try {
        for(let i = 0; i < this.notifications.length; i++)
        {
          console.log(String(this.notifications[i].taskID), "|", String(data.taskID) ," = ", (String(this.notifications[i].taskID) === String(data.taskID)));
          if (String(this.notifications[i].taskID) === String(data.taskID)) {
            console.log("Drop Index: ", i)
            currentObj.notifications.splice(i,1);
            throw "Break";
          }
        }
      } catch (e) {
        if (e !== "Break") throw e;
      }

        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.taskIO.emit("hir_sync", {
          token: this.apiKey,
          deviceID: this.userName,
          data:{cid: currentObj.customerID,}
        });
    },
  },
  beforeDestroy() {
    sessionStorage.clear();
    clearInterval(this.intervalSession);
    clearInterval(this.intervalSessionStatus);
  },
};
</script>

<style>
.logo-img-bg{
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 0;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  border: 1px solid black;
}
.home {
  background-color: gray;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  position: relative;
}
.nav-mobile {
  display: none;
}
.footer-space {
  display: none;
  height: 63px;
  width: 100%;
  /* // display: block; */
  background: red;
}
.content-space {
  height: 100vh !important;
  padding: 0px;
  margin: 0px;
}
/* // #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   // height: 100vh;
//   width: 100wh;
//   background-color:lightgray;
//   // overflow: hidden;
// }

// #nav {
//   // padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// } */
.mainnav {
  display: inline;
  transition: display 300ms;
  z-index: 100;
}
.main-content {
  background: #f1f5f9;
  height: 100vh;
  position: fixed;
  /* // overflow-y: scroll; */
  overflow-x: auto;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  transition: left 300ms;
}
@media only screen and (max-width: 560px) {
  .mainnav {
    display: none;
  }
  .footer-space {
    display: block;
  }

  .main-content {
    left: 0;
    width: 100%;
  }
  .content-space {
    height: calc(100vh - 63px);
  }

  #main .search-wrapper {
    display: none;
  }
  .nav-mobile {
    display: inline;
  }
}
@media only screen and (max-height: 560px) {
  .mainnav {
    display: none;
  }
  .nav-mobile {
    display: inline;
  }

  .footer-space {
    display: block;
  }

  .content-space {
    height: calc(100vh - 63px);
  }

  .main-content {
    left: 0;
    width: 100%;
  }

  #main .search-wrapper {
    display: none;
  }
}
</style>
