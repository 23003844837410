<template>
  <div>
    <div class="main-content" style="background: var(--color-dark)">
      <header1
        enableOnlineStatus
        :lastUpdate="deviceStatus.time.value"
        enableStatus
        :deviceInfo="customerAssets.devices[pageRanderInfo.deviceIndex]"
      ></header1>
      <b-container fluid>
        <b-row align-h="center">
          <b-col class="test1" sm="6" style="padding: 0px; margin: 0px"
            ><iframe
              v-if="camData"
              class="video-main"
              :src="videoServer + camData[0].streamingKey + '&playOrder=webrtc'"
              frameborder="0"
              playsinline
              allowfullscreen
            ></iframe
          ></b-col>
          <b-col sm="3">
            <b-row class="test1"
              ><iframe
                v-if="camData"
                class="video-mini"
                :src="videoServer + camData[1].streamingKey + '&playOrder=webrtc'"
                frameborder="0"
                playsinline
                allowfullscreen
              ></iframe
            ></b-row>
            <b-row class="test1"
              ><iframe
                v-if="camData"
                class="video-mini"
                :src="videoServer + camData[2].streamingKey + '&playOrder=webrtc'"
                frameborder="0"
                playsinline
                allowfullscreen
              ></iframe
            ></b-row>
          </b-col>
          <b-col class="test1" sm="3">
            <b-row class="card-data-right">
              <button
                class="emergency-btn"
                type="button"
                @click="sendCommand(cmd_emer)"
              >
                EMERGENCY
              </button></b-row
            >
            <b-row class="card-data-right" v-if="deviceStatus.task.hir.value">
              <small>Human Interaction Request</small>
              <h3>
                Please consider to allow for
                {{ deviceStatus.task.hir.msg }}?
                <small
                  >[Auto reject in
                  {{ deviceStatus.task.hir.timeout.toFixed(0) }}
                  sec.]</small
                >
              </h3>
              <div style="width: 100%">
                <button
                  class="approve-btn approve-btn-gn"
                  type="button"
                  @click="hirActionButton(true)"
                >
                  Approve</button
                ><button
                  class="approve-btn approve-btn-rd"
                  type="button"
                  @click="hirActionButton(false)"
                >
                  Reject
                </button>
              </div>
            </b-row>
            <b-container fluid>
              <b-tabs
                card
                dark
                nav-wrapper-class="w-15"
                style="font-size: 0.75rem; margin: 0px; padding: 0px"
                class="card-data-value"
              >
                <b-tab title="Information" class="detailStatus" active>
                  <b-container fluid>
                    <b-row align-h="center">
                      <div class="sideInfo">
                        <b-row class="card-data-right">
                          <small>BOX ID</small>
                          <h3>
                            {{
                              customerAssets.devices[pageRanderInfo.deviceIndex]
                                .aid
                            }}
                          </h3>
                        </b-row>
                        <b-row class="card-data-right" sm="2"
                          ><small>BOX Name</small>
                          <h3>
                            {{
                              customerAssets.devices[pageRanderInfo.deviceIndex]
                                .name
                            }}
                          </h3>
                        </b-row>
                        <b-row class="card-data-right" sm="2">
                          <small>Site Name</small>
                          <h3>
                            {{
                              customerAssets.sites[pageRanderInfo.siteIndex]
                                .name
                            }}
                          </h3>
                        </b-row>
                        <b-row class="card-data-right" sm="2"
                          ><small>Last Update</small>

                          <h3>
                            {{ showDate(deviceStatus.time.value) }} |
                            {{ showTime(deviceStatus.time.value) }}
                          </h3>
                        </b-row>
                        <b-row class="card-data-right" sm="2"
                          ><small>Mode</small>
                          <h3>{{ deviceStatus.operation.data.mode.value }}</h3>
                        </b-row>
                      </div>
                    </b-row>
                  </b-container>
                </b-tab>
                <b-tab title="Command Panel" class="detailStatus">
                  <b-container fluid>
                    <div class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button block v-b-toggle.accordion-1 variant="info"
                            >Operation Mode</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-1"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <button
                              v-for="item in cmd_modes"
                              :key="item.name"
                              class="cmd-btn"
                              @click="sendCommand(item)"
                            >
                              {{ item.name }}
                            </button>
                          </b-card-body>
                        </b-collapse>
                      </b-card>

                      <b-card
                        no-body
                        class="mb-1"
                        v-if="
                          deviceStatus.operation.data.mode.value ===
                            'Operation Mode' ||
                          deviceStatus.operation.data.mode.value ===
                            'Emergency Stop' ||
                          deviceStatus.operation.data.mode.value ===
                            'Simulation Mode'
                        "
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button block v-b-toggle.accordion-2 variant="info"
                            >Operation Command</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-2"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <button
                              v-for="item in cmd_opers"
                              :key="item.name"
                              class="cmd-btn"
                              @click="sendCommand(item)"
                            >
                              {{ item.name }}
                            </button>
                          </b-card-body>
                        </b-collapse>
                      </b-card>

                      <b-card
                        no-body
                        class="mb-1"
                        v-if="
                          deviceStatus.operation.data.mode.value ===
                            'Maintenance Mode' ||
                          deviceStatus.operation.data.mode.value ===
                            'Safety Override Mode'
                        "
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button block v-b-toggle.accordion-3 variant="info"
                            >Operation Command</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-3"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <button
                              v-for="item in cmd_element"
                              :key="item.name"
                              class="cmd-btn"
                              @click="sendCommand(item)"
                            >
                              {{ item.name }}
                            </button>
                            <button
                              v-for="item in cmd_uav"
                              :key="item.name"
                              class="cmd-btn"
                              @click="sendCommand(item)"
                            >
                              {{ item.name }}
                            </button>
                            <button
                              v-for="item in cmd_util"
                              :key="item.name"
                              class="cmd-btn"
                              @click="sendCommand(item)"
                            >
                              {{ item.name }}
                            </button>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </b-container>
                </b-tab>
              </b-tabs>
            </b-container>
          </b-col>
          <b-col sm="8" style="margin-top: 10px">
            <b-container fluid>
              <b-row
                align-h="between"
                class="card-data-value"
                style="font-size: 0.75rem; margin: 0px"
              >
                <b-col class="card-data-value" sm="2">
                  <small>Mode</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.operation.data.mode.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Service</small>
                  <h3
                    :class="[
                      deviceStatus.operation.data.process.value.includes(
                        'Ready'
                      )
                        ? 'card-data-value green'
                        : 'card-data-value value-processing',
                      'card-data-value',
                    ]"
                  >
                    {{ deviceStatus.operation.data.process.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Cover Status</small>
                  <h3
                    :class="[
                      deviceStatus.status.data.cover.value === 'Open' ||
                      deviceStatus.status.data.cover.value === 'Closed'
                        ? 'card-data-value green'
                        : 'card-data-value value-moving',
                      'card-data-value red',
                    ]"
                  >
                    {{ deviceStatus.status.data.cover.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Lifting Status</small>
                  <h3
                    :class="[
                      deviceStatus.status.data.x_lift.value === 'Up' ||
                      deviceStatus.status.data.x_lift.value === 'Down'
                        ? 'card-data-value green'
                        : 'card-data-value value-moving',
                      'card-data-value red',
                    ]"
                  >
                    {{ deviceStatus.status.data.x_lift.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Centerlizer-X Status</small>
                  <h3
                    :class="[
                      deviceStatus.status.data.cen_x.value === 'Released' ||
                      deviceStatus.status.data.cen_x.value === 'Centered'
                        ? 'card-data-value green'
                        : 'card-data-value value-moving',
                      'card-data-value red',
                    ]"
                  >
                    {{ deviceStatus.status.data.cen_x.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Centerlizer-Y Status</small>
                  <h3
                    :class="[
                      deviceStatus.status.data.cen_y.value === 'Released' ||
                      deviceStatus.status.data.cen_y.value === 'Centered'
                        ? 'card-data-value green'
                        : 'card-data-value value-moving',
                      'card-data-value  red',
                    ]"
                  >
                    {{ deviceStatus.status.data.cen_y.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Beacon Status</small>
                  <h3
                    :class="[
                      deviceStatus.status.data.beacon.value === 'On'
                        ? 'card-data-value green'
                        : 'card-data-value red',
                      'card-data-value red',
                    ]"
                  >
                    {{ deviceStatus.status.data.beacon.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Charger Status</small>
                  <h3
                    :class="[
                      deviceStatus.charger.data.status.value.includes('Charge')
                        ? 'card-data-value green'
                        : 'card-data-value red',
                      'card-data-value',
                    ]"
                  >
                    {{ deviceStatus.charger.data.status.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Charger Mode</small>
                  <h3
                    :class="[
                      deviceStatus.charger.data.mode.value.includes('Charge')
                        ? 'card-data-value green'
                        : 'card-data-value red',
                      'card-data-value',
                    ]"
                  >
                    {{ deviceStatus.charger.data.mode.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small
                    v-if="
                      deviceStatus.charger.data.mode.value.includes('Charge')
                    "
                    >Charging Voltage</small
                  >
                  <small v-else>Drone Batterry</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.charger.data.volt_out.value }}
                    <small>v</small>
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Charging Current</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.charger.data.current_out.value }}
                    <small> A</small>
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Drone Battery</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.charger.data.battery.value }}
                    <small> %</small>
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>AC Power</small>
                  <h3
                    class="card-data-value green"
                    v-if="deviceStatus.UPS.data.volt_in.value > 150"
                  >
                    Available
                  </h3>
                  <h3 class="card-data-value red value-moving" v-else>
                    Not Available
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Power Consumption</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.UPS.data.watt_out.value }}
                    <small>Watt</small>
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Task Execution</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.task.data.available.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Task ID</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.task.data.taskID.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Cloud Connection</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.system.data.cloud.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Local Connection</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.system.data.local.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Box Controller Connection</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.system.data.con_comm.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Weather Connection</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.system.data.weather_comm.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Batt. Cells Connection</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.system.data.cell_monitor.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Rain Status</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.weather.data.rainStatus.value }}
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Rain Sens</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.weather.data.rainSens.value }} mm
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Wind Speed</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.weather.data.windSpeed.value }} m/s
                  </h3>
                </b-col>
                <b-col class="card-data-value" sm="2">
                  <small>Wind Direction</small>
                  <h3 class="card-data-value">
                    {{ deviceStatus.weather.data.windDir.value }}
                  </h3>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col class="test1" sm="4">
            <div class="content1">
              <h1>Log Message</h1>
              <div class="logView">
                <table>
                  <tr
                    v-for="item in logmsg"
                    :key="
                      item.time + (Math.random() + 1).toString(36).substring(7)
                    "
                  >
                    <td>
                      {{ item.date }}
                    </td>
                    <td>
                      {{ item.time }}
                    </td>
                    <td>
                      {{ item.msg }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import header1 from "../components/header/Header_Normal.vue";
import io from "socket.io-client";
import swal from "sweetalert2";
export default {
  name: "Dashboard",
  components: {
    header1,
  },
  props: {
    serverTime: Object,
  },
  data() {
    return {
      pageRanderInfo: {
        deviceID: -1,
        deviceIndex: -1,
        siteIndex: -1,
        deviceKey: "",
      },
      statusData: [],
      deviceStatus: {
        time: { key: "Last Update", value: 0.0 },
        Sim_Mode: { key: "Simulation Mode", value: "--" },
        operation: {
          name: "Operation Status",
          data: {
            command: {
              key: "Request Service",
              value: "--",
              unit: "",
            },
            status: { key: "Process Status", value: "--", unit: "" },
            process: { key: "Service", value: "--", unit: "" },
            other: { key: "Other Request", value: "--", unit: "" },
            mode: { key: "Mode", value: "--", unit: "" },
            v1: { key: "Firmware Version", value: "--", unit: "" },
          },
        },
        status: {
          name: "Station Status",
          data: {
            cover: { key: "Cover", value: "--", unit: "" },
            cover_err: { key: "Cover Err", value: "--", unit: "" },
            x_lift: { key: "X-Lift", value: "--", unit: "" },
            x_lift_err: { key: "X-Lift Err", value: "--", unit: "" },
            cen_x: { key: "CEN-X", value: "--", unit: "" },
            cen_x_err: { key: "CEN-X Err", value: "--", unit: "" },
            cen_y: { key: "CEN-Y", value: "--", unit: "" },
            cen_y_err: { key: "CEN-Y Err", value: "--", unit: "" },
            light: { key: "Lighting", value: "--", unit: "" },
            beacon: { key: "Beacon", value: "--", unit: "" },
            cover_sensor: { key: "COVER Sensor", value: "--", unit: "" },
            lift_sensor: { key: "LIFT Sensor", value: "--", unit: "" },
            cen_x_sensor: { key: "CEN-X Sensor", value: "--", unit: "" },
            cen_y_sensor: { key: "CEN-Y Sensor", value: "--", unit: "" },
          },
        },
        charger: {
          name: "Charger Status",
          data: {
            status: { key: "Status", value: "--", unit: "" },
            mode: { key: "Mode", value: "--", unit: "" },
            volt_in: { key: "Voltage Input", value: 0.0, unit: "V" },
            current_in: { key: "Current Input", value: 0.0, unit: "A" },
            volt_out: { key: "Voltage Output", value: 0.0, unit: "V" },
            current_out: { key: "Current Output", value: 0.0, unit: "A" },
            battery: { key: "Battery", value: 0, unit: "%" },
            board_temp: { key: "Board Temp.", value: 0.0, unit: "Deg.C" },
            charger_err: { key: "Charger Err", value: "--", unit: "" },
            BSR: { key: "Resistant", value: 0, unit: "mOhm" },
            iCharge: { key: "iCharge BSR", value: 0, unit: "" },
            qCount: { key: "Q Count", value: 0, unit: "" },
            configBit: { key: "Config Bit", value: 0, unit: "" },
          },
        },
        condition: {
          name: "Condition Status",
          data: {
            box_temp: { key: "Box Temp.", value: 0, unit: "Deg.C" },
            box_humi: { key: "Box Humi.", value: 0, unit: "%RH" },
            batt_temp: { key: "Battery Temp.", value: 0, unit: "Deg.C" },
          },
        },
        UPS: {
          name: "Power System",
          data: {
            volt_in: { key: "Volt Input", value: 0, unit: "VAC" },
            volt_out: { key: "Volt Output", value: 0, unit: "VAC" },
            currect_out: { key: "Current Output", value: 0, unit: "A" },
            watt_out: { key: "Power Consump.", value: 0, unit: "W" },
            watt_out_va: { key: "Power VA", value: 0, unit: "VA" },
            frq_out: { key: "Frequency Output", value: 0, unit: "Hz" },
            batt_temp: { key: "Temp.", value: 0.0, unit: "Deg.C" },
            expire: { key: "Expire", value: "--", unit: "" },
            batt: { key: "Battery", value: 0.0, unit: "%" },
            ups_err: { key: "UPS Err", value: "--", unit: "" },
            sn: { key: "Serial No.", value: "--    ", unit: "" },
          },
        },
        config: {
          name: "Configuration",
          data: {
            co_op_al_tim: {
              key: "Cover Open Warn Time",
              value: 0,
              unit: "Sec",
            },
            co_op_err_tim: {
              key: "Cover Open Error Time",
              value: 0,
              unit: "Sec",
            },
            co_cl_al_tim: {
              key: "Cover Close Warn Time",
              value: 0,
              unit: "Sec",
            },
            co_err_al_tim: {
              key: "Cover Close Error Time",
              value: 0,
              unit: "Sec",
            },
            li_up_al_tim: { key: "Lift-Up Warn Time", value: 0, unit: "Sec" },
            li_up_err_tim: {
              key: "Lift-Up Error Time",
              value: 0,
              unit: "Sec",
            },
            li_dw_al_tim: {
              key: "Lift-Down Warn Time",
              value: 0,
              unit: "Sec",
            },
            li_dw_err_tim: {
              key: "Lift-Down Error Time",
              value: 0,
              unit: "Sec",
            },
            cen_x_al_tim: { key: "Cen-X Warn Time", value: 0, unit: "Sec" },
            cen_x_err_tim: { key: "Cen-X Error Time", value: 0, unit: "Sec" },
            rel_x_al_tim: { key: "Rel-X Warn Time", value: 0, unit: "Sec" },
            rel_x_err_tim: { key: "Rel-X Error Time", value: 0, unit: "Sec" },
            cen_y_al_tim: { key: "Cen-Y Warn Time", value: 0, unit: "Sec" },
            cen_y_err_tim: { key: "Cen-Y Error Time", value: 0, unit: "Sec" },
            rel_y_al_tim: { key: "Rel-Y Warn Time", value: 0, unit: "Sec" },
            rel_y_err_tim: { key: "Rel-Y Error Time", value: 0, unit: "Sec" },
            move_tim: { key: "Moving time", value: 0, unit: "Sec" },
          },
        },
        weather: {
          name: "Weather Information",
          data: {
            windSpeed: { key: "Wind Speed", value: 0, unit: "m/s" },
            windDir: { key: "Wind Direction", value: 0, unit: "Deg." },
            rainSens: { key: "Rain Sens", value: 0, unit: "mm" },
            rainEventAcc: { key: "Rain Event Acc", value: 0.0, unit: "mm" },
            rainTotalAcc: { key: "Rain Total Acc", value: 0.0, unit: "mm" },
            rainRint: { key: "Rain Rint", value: 0, unit: "mm/Hr" },
            rainStatus: { key: "Rain Status", value: "--", unit: "" },
            faultStatus: { key: "Fault Status", value: "Comm. Lost", unit: "" },
            resetRainTotalAcc: {
              key: "Reset Rain Total Acc",
              value: 0,
              unit: "",
            },
          },
        },
        drone_battery: {
          name: "Drone Battery",
          data: {
            cell_volt_2: { key: "Cell2 Volt", value: 0.0, unit: "V" },
            cell_volt_3: { key: "Cell3 Volt", value: 0.0, unit: "V" },
            batt_temp: {
              key: "Battery Temperature",
              value: 0.0,
              unit: "Deg.C",
            },
            cell_volt_1: { key: "Cell1 Volt", value: 0.0, unit: "V" },
            cell_volt_6: { key: "Cell6 Volt", value: 0.0, unit: "V" },
            charging_cycle: { key: "Charging Count", value: 0.0, unit: "" },
            cell_volt_4: { key: "Cell4 Volt", value: 0.0, unit: "V" },
            cell_volt_5: { key: "Cell5 Volt", value: 0.0, unit: "V" },
          },
        },
        device_id: { key: "Box ID", value: "--" },
        system: {
          name: "System Status",
          data: {
            con_comm: {
              key: "Controller Connection",
              unit: "",
              value: "--",
            },
            con_decode: { key: "Controller Decode", unit: "", value: "--" },
            weather_comm: {
              key: "Weather Connection",
              unit: "",
              value: "--",
            },
            weather_decode: {
              key: "Weather Decode",
              unit: "",
              value: "--",
            },
            cell_monitor: {
              key: "Batt Cell Interface",
              unit: "",
              value: "--",
            },
            cloud: { key: "Cloud Connection", unit: "", value: "--" },
            local: { key: "Local Connection", unit: "", value: "--" },
          },
        },
        task: {
          name: "Task Status",
          data: {
            available: { key: "Task Status", unit: "", value: "" },
            taskID: { key: "Task ID", value: "", unit: "" },
            droneID: { key: "Drone ID", value: "", unit: "" },
          },
          hir: {
            key: "",
            value: false,
            timeout: 0,
            msg: "",
          },
        },
      },
      isInitialPage: true,
      socket: null,
      control: null,
      taskIO:null,
      webAPI: this.$store.state.webAPI,
      // deviceKey: this.$store.state.deviceKey,
      // selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      // apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      assetList: {},
      logmsg: [],
      //////////////////////////////////////////////////////////////////
      cmd_emer: {
        name: "Emergency",
        data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          type: 0,
          command: 2000,
          data: 0,
        },
      },
      cmd_modes: [
        {
          name: "Operation Mode",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 200,
            command: 0,
            data: 0,
          },
        },
        {
          name: "Maint. Mode",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 200,
            command: 100,
            data: 0,
          },
        },
        {
          name: "Safety Override",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 200,
            command: 200,
            data: 0,
          },
        },
        {
          name: "Simulation Mode",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 200,
            command: 300,
            data: 0,
          },
        },
      ],
      cmd_opers: [
        {
          name: "Open",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 100,
            data: 0,
          },
        },
        {
          name: "Close",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 200,
            data: 0,
          },
        },
        {
          name: "Off UAV Seq",
          // name: "Off UAV & On Charger",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 500,
            data: 0,
          },
        },
        {
          name: "On Signal",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 2500,
            data: 0,
          },
        },
        {
          name: "Off Signal",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 2600,
            data: 0,
          },
        },
        {
          name: "Clear Command",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 0,
            data: 0,
          },
        },
        {
          name: "Reset/Re-initial",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 2400,
            data: 0,
          },
        },
      ],
      cmd_element: [
        {
          name: "Open Cover",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 800,
            data: 0,
          },
        },
        {
          name: "Close Cover",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 900,
            data: 0,
          },
        },
        {
          name: "Lift-Up",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1000,
            data: 0,
          },
        },
        {
          name: "Lift-Down",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1100,
            data: 0,
          },
        },
        {
          name: "Center All",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1200,
            data: 0,
          },
        },
        {
          name: "Center X",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1300,
            data: 0,
          },
        },
        {
          name: "Center Y",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1400,
            data: 0,
          },
        },
        {
          name: "Release All",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1500,
            data: 0,
          },
        },
        {
          name: "Release X",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1600,
            data: 0,
          },
        },
        {
          name: "Release Y",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1700,
            data: 0,
          },
        },
        // {
        //   name: "Emergency",
        //   data: {
        //     type: 0,
        //     command: 2000,
        //     data: 0,
        //   },
        // },
      ],
      cmd_uav: [
        {
          name: "Off UAV",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 300,
            data: 0,
          },
        },
        {
          name: "On UAV",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 400,
            data: 0,
          },
        },
        {
          name: "Off UAV SEQ",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 500,
            data: 0,
          },
        },
        {
          name: "On Charger",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 501,
            data: 0,
          },
        },

        {
          name: "Off Charger",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 502,
            data: 0,
          },
        },
      ],
      cmd_util: [
        {
          name: "On Signal",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 2500,
            data: 0,
          },
        },
        {
          name: "Off Signal",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 2600,
            data: 0,
          },
        },
        {
          name: "On Beacon",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1800,
            data: 0,
          },
        },
        {
          name: "Off Beacon",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 1900,
            data: 0,
          },
        },
        {
          name: "On Light",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 600,
            data: 0,
          },
        },
        {
          name: "Off Light",
          data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 0,
            command: 700,
            data: 0,
          },
        },
      ],
      //////////////////////////////////////////////////////////////////
      selectedType: "All",
      optionsType: [
        { text: "Station Only", value: "Station" },
        { text: "Drone Only", value: "Drone" },
        { text: "All", value: "All" },
      ],
      boxDataStatus: {},
      // statusData: [],
      realTimeServer: this.$store.state.apiRealtimeServe,
      videoServer: this.$store.state.videoServer,
      boxid: "BoxSim9", //this.$store.state.selectedBoxID,
      droneid: "DroneSim9", //this.$store.state.selectedDroneID,
      timeNow: { time: null, date: null },
      datashowing: {
        spinner: true,
        boxdata: {},
        dronedata: {
          hdop: 0,
          gpsSat: 0,
          battPercent: 0,
          battVoltage: 0.0,
          rssi: 0,
          operatingMode: "Unknow",
          arm: false,
          time: 0,
        },
      },
      // socket: null,
      filter: "",
      ping: {
        sendtime: 0,
        receivetime: 0,
        delay: 0,
      },
      servertime: { date: null, time: null },
      servermessage: [],
      dronestatus: {
        hdop: 0.9,
        gpsSat: 12,
        battPercent: 90,
        battVoltage: 20.23,
        rssi: 90,
        operatingMode: "Auto",
        arm: true,
        time: 0,
      },
      camData: [],
      boxtatus: {},
      isSimulate: false,
      interValDrone: null,
      interValBox: null,
      interValTime: null,
    };
  },
  beforeCreate() {
    // console.log(this.$sio.id);
  },
  computed: {
    stationID() {
      return this.$route.params.stationID;
    },
    apiKey() {
      return this.$store.state.apiKey;
    },
  },
  watch: {
    stationID(newValue, oldValue) {
      // Do something with the new value of my_state
      console.log(`stationID changed from ${oldValue} to ${newValue}`);
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.socket.disconnect();
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.control.disconnect();
      let id = this.$route.params.stationID;
      let currentObj = this;
      console.log("ID: ", id);
      currentObj.logmsg = [];
      currentObj.isInitialPage = true;
      if (id) {
        console.log("ID: ", id);
        let key1 = this.getKey(id);
        console.log("Key : ", key1);
        currentObj.pageRanderInfo.deviceID = id;
        this.getIndex();
        this.init_function();
      } else {
        swal.fire({
          icon: "error",
          title: "Oops... ",
          text: "Something went wrong! Please try again later",
        });
      }
    },
  },
  created() {
    let id = this.$route.params.stationID;
    let currentObj = this;
    console.log("ID: ", id);
    if (id) {
      console.log("ID: ", id);
      let key1 = this.getKey(id);
      console.log("Key : ", key1);
      currentObj.pageRanderInfo.deviceID = id;
      this.getIndex();
    } else {
      swal.fire({
        icon: "error",
        title: "Oops... ",
        text: "Something went wrong! Please try again later",
      });
    }
  },
  mounted() {
    if (this.customerID == null) {
      this.customerID = sessionStorage.getItem("setcustomerID");
    }
    if (this.apiKey == null) {
      this.apiKey = sessionStorage.getItem("setAPIKey");
    }
    this.taskIO = io(this.sioServer + "/task", {
        auth: { deviceID: this.userName, token: this.apiKey },
        transports: ["websocket"],
      });
    this.socket = new window.io(this.sioServer + "/station/status", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });

    this.control = new window.io(this.sioServer + "/command", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });
    this.socket.on("log", (data) => this.logHandle(data));
    this.socket.on("log_init", (data) => {
      console.log("Log initial!");
      data.forEach(this.logHandle);
    });
    this.socket.on("cache_status", (data) => {
      console.log("cache_status");
      this.onStatus(data);
    });
    this.socket.on("connect", this.onConnect);
    this.taskIO.on("connect", console.log('TaskIO is connected'));
    this.control.on("connect", this.onConnectControl);
    this.socket.on("status", (data) => this.onStatus(data));
    // this.socket.on("hir_request", (data) => this.onHIR_Req(data));
    // this.socket.on("hir_cancel", (data) => this.onHIR_Cancel(data));
    this.socket.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });
    this.control.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });

    this.getCamData();
    // if ( === null || this.droneid === null) {
    //   swal
    //     .fire({
    //       icon: "error",
    //       title: "Incorrect of system ID!!",
    //       text: "Base ID : " + this.boxid + " | UAV ID : " + this.vUAVID,
    //       showCancelButton: false,
    //       confirmButtonText: `Ok`,
    //     })
    //     .then((result) => {
    //       if (result.isConfirmed) {
    //         window.location.href = "/#/";
    //       } else {
    //         window.location.href = "/#/";
    //       }
    //     });
    // }
    // this.calTime();
    // this.interValTime = setInterval(this.calTime, 100);

    // this.getBoxData();
    // this.getDroneData();

    // this.interValBox = setInterval(this.getBoxData, 1000);
    // this.interValDrone = setInterval(this.getDroneData, 1000);

    // this.socket.on("my_pong", this.receiveData);
    // this.socket.on("log", (data) => this.logHandle(data));
    // this.socket.on("status", (data) => this.onStatus(data));
  },
  methods: {
    async init_function() {
      this.socket = new window.io(this.sioServer + "/station/status", {
        auth: { deviceID: this.userName, token: this.apiKey },
        transports: ["websocket"],
      });

      this.control = new window.io(this.sioServer + "/command", {
        auth: { deviceID: this.userName, token: this.apiKey },
        transports: ["websocket"],
      });
      this.socket.on("log", (data) => this.logHandle(data));
      this.socket.on("log_init", (data) => {
        console.log("Log initial!");
        data.forEach(this.logHandle);
      });
      this.socket.on("cache_status", (data) => {
        console.log("cache_status");
        this.onStatus(data);
      });
      this.socket.on("connect", this.onConnect);
      this.control.on("connect", this.onConnectControl);
      this.socket.on("status", (data) => this.onStatus(data));
      // this.socket.on("hir_request", (data) => this.onHIR_Req(data));
      // this.socket.on("hir_cancel", (data) => this.onHIR_Cancel(data));
      this.socket.on("authen", (data) => {
        console.log(data);
        sessionStorage.clear();
        this.$store.commit("setLoginState", false);
        window.location.href = "/#/";
      });
      this.control.on("authen", (data) => {
        console.log(data);
        sessionStorage.clear();
        this.$store.commit("setLoginState", false);
        window.location.href = "/#/";
      });

      this.getCamData();
    },
    async getIndex() {
      let currentObj = this;
      let siteID = -1;
      this.customerAssets.devices.forEach((item, index) => {
        if (parseInt(item.aid) === parseInt(this.pageRanderInfo.deviceID)) {
          currentObj.pageRanderInfo.deviceIndex = index;
          siteID = parseInt(item.sid);
        }
      });
      if (siteID >= 0) {
        this.customerAssets.sites.forEach((item, index) => {
          if (parseInt(item.sid) === siteID) {
            currentObj.pageRanderInfo.siteIndex = index;
          }
        });
      }
    },
    async getKey(device_id) {
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.pageRanderInfo.deviceKey = deviceKey;
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    async getCamData() {
      // console.log('-----PING-------');
      if (this.pageRanderInfo.deviceID >= 0) {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.boxid);
        console.log(
          "Call : ",
          this.webAPI +
            "/app/api/streamingmanagement?aid=" +
            this.pageRanderInfo.deviceID
        );
        await this.axios
          .get(
            this.webAPI +
              "/app/api/streaming?aid=" +
              this.pageRanderInfo.deviceID,
            {
              headers: {
                authorization: this.apiKey,
              },
            }
          )
          .then(function (response) {
            console.log("Cam Data : ", response);
            currentObj.camData = response.data;
          })
          .catch(function (error) {
            console.warn(error);
            swal.fire({
              icon: "error",
              title: "Oops... ",
              text: "Something went wrong! Please try again later",
            });
          });
      }
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
    onHIR_Cancel(data) {
      console.log(data);
      swal.close();
    },
    onHIR_Req(data) {
      var boxID = data.boxID;
      var droneID = data.droneID;
      var TaskID = data.taskID;
      // deepcode ignore PromiseNotCaughtGeneral: <please specify a reason of ignoring this>
      swal
        .fire({
          title: "Do you allow to continue task?",
          text:
            "This is Task Permission Approval! \n\n Task ID: " +
            data.taskID +
            "\n\n BoxID ID: " +
            data.boxID,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Approve",
          cancelButtonText: "Reject",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.hirAction(TaskID, boxID, droneID, true);

            swal.fire({
              title: "Approved!",
              text: "Your decision has been sent.",
              timer: 1500,
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === swal.DismissReason.cancel
          ) {
            this.hirAction(TaskID, boxID, droneID, false);
            swal.fire({
              title: "Rejected!",
              text: "Your decision has been sent.",
              timer: 1500,
              icon: "error",
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        })
        .catch(e => console.error(e));
    },
    async hirActionButton(result) {
      let TaskID = this.deviceStatus.task.data.taskID.value;
      let boxID = this.pageRanderInfo.deviceID;
      let DroneID = this.deviceStatus.task.data.droneID.value;
      console.log("Hir Action : ", result);
      console.log();
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      await this.taskIO.emit("hir_res", {
        token: this.apiKey,
        deviceID: this.userName,
        time: Date.now(),
        data: {
          msgFrom: this.userName,
          msgTo: boxID,
          taskID: TaskID,
          response: {
            command: null,
            result: result ? 1 : 4,
          },
        },
      });
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      await this.control.emit("hir", {
        deviceID: this.userName,
        token: this.apiKey,
        msgTye: "hir_result",
        data: {
          msgFrom: this.userName,
          msgTo: boxID,
          command: {
            taskID: TaskID,
            boxID: boxID,
            droneID: DroneID,
            result: result,
          },
        },
      });
    },
    async hirAction(TaskID, boxID, DroneID, result) {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      await this.control.emit("hir", {
        deviceID: this.userName,
        token: this.apiKey,
        msgTye: "hir_result",
        data: {
          msgFrom: this.userName,
          msgTo: boxID,
          command: {
            taskID: TaskID,
            boxID: boxID,
            droneID: DroneID,
            result: result,
          },
        },
      });
      //$("#hir").html('');
      // var txtResult = "Rejected";
      // if (result) {
      //   txtResult = "Approved";
      // }
      // await control.emit("task", {
      //   deviceID: deviceID,
      //   token: token,
      //   msgTye: "task_status",
      //   data: {
      //     msgFrom: "UI",
      //     taskID: TaskID,
      //     time: new Date().getTime(),
      //     status: "Permission " + txtResult,
      //   },
      // });
      // return false;
    },
    // pingtest() {
    //   this.ping.sendtime = new Date().getTime();
    //   this.$sio.emit("my_ping");
    // },
    onStatus(data) {
      // // console.log(data);
      // let currentObj = this;
      // currentObj.statusData = [];
      // currentObj.boxDataStatus = data;
      // for (const property in data.data) {
      //   this.createTable(data.data[property]);
      // }
      // console.log(data);
      let currentObj = this;
      currentObj.statusData = [];
      let existData = this.deviceStatus;
      // console.log('Exi : ',JSON.stringify(existData))
      let newData = data.data;
      // console.log('New : ',JSON.stringify(newData))
      currentObj.deviceStatus = Object.assign(existData, newData);
      // for (const property in data.data) {
      //   this.createTable(data.data[property]);
      // }
    },
    createTable(data) {
      if (data.name) {
        for (const property in data.data) {
          this.createList(data.data[property]);
        }
      }
    },
    createList(data) {
      //console.log(type)
      // console.log(data);

      let currentObj = this;
      currentObj.statusData.push(data);
      // $("#status").append("<tr>");
      // $("#status").append("<td>" + data.key + "</td>");
      // $("#status").append("<td>" + data.value + "</td>");
      // $("#status").append("<td>" + data.unit + "</td>");
      // $("#status").append("</tr>");
    },
    async onConnect() {
      let currentObj = this;
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      if (this.socket.connected) {
        console.log("Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("join", {
          token: this.apiKey,
          room: currentObj.pageRanderInfo.deviceKey,
        });
        if (this.isInitialPage) {
          console.log("Initial Page");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.socket.emit("initdata", {
            token: this.apiKey,
            room: currentObj.pageRanderInfo.deviceKey,
          });
          currentObj.isInitialPage = false;
        }
      }
    },
    async onConnectControl() {
      let currentObj = this;
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      if (this.socket.connected) {
        console.log("Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("join", {
          token: this.apiKey,
          room: currentObj.pageRanderInfo.deviceKey,
        });
      }
    },
    sendCommand(data) {
      if (this.control.connected) {
        console.log("Sending Command : ", data.name);
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.control.emit("command", {
          token: this.apiKey,
          deviceID: this.pageRanderInfo.deviceKey,
          cmd: {
            commandID: 1,
            taskID: 0,
            type: data.data.type,
            command: data.data.command,
            data: data.data.data,
          },
        });
      }
    },
    receiveData() {
      let currentObj = this;
      currentObj.ping.receivetime = new Date().getTime();
      currentObj.ping.delay =
        currentObj.ping.receivetime - currentObj.ping.sendtime;
    },
    logHandle(data) {
      let currentObj = this;
      console.log(data.msg);
      var date = new Date(data.time);
      // const options = {year: 'numeric', month: 'long', day: 'numeric' };
      currentObj.logmsg.unshift({
        date: date.toLocaleDateString("th-TH"),
        time: date.toLocaleTimeString("th-TH", { hour12: false }),
        msg: data.msg,
      });
    },
    showDate(dd) {
      var date = new Date(dd);
      return date.toLocaleDateString("th-TH");
    },
    showTime(dd) {
      var date = new Date(dd);
      return date.toLocaleTimeString("th-TH", { hour12: false });
    },
    calTime() {
      var current_datetime = new Date();
      // var caldate =
      this.timeNow.date =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        current_datetime.getDate().toString().padStart(2, "0");
      this.timeNow.time =
        // var caltime =
        current_datetime.getHours().toString().padStart(2, "0") +
        ":" +
        current_datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        current_datetime.getSeconds().toString().padStart(2, "0");
      // return { date: caldate, time: caltime };
    },
    scrolldown() {
      this.$refs["card_body"].scrollTop =
        this.$refs["card_body"].scrollHeight + 100;
      // console.log(this.$refs['card_body'].scrollHeight);
    },
    async dronedata() {
      let currentObj = this;
      if (!this.isSimulate) {
        await this.axios
          .get(this.apiServer + "/drone/v210/status/sim1")
          .then(function (response) {
            // console.log('Drone res_code : ', response.status)
            if (response.status == 200) {
              currentObj.dronestatus = response.data;
            }
          })
          .catch(function (error) {
            // currentObj.output2 = error;
            console.warn(error);
          });
      }
    },
    async boxdata() {
      if (!this.isSimulate) {
        let currentObj = this;
        // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
        await this.axios
          .get(this.apiServer + "/box/v210/status/base1") // + this.boxID)
          .then(function (response) {
            // console.log('BOX res_code : ', response.status)
            if (response.status == 200) {
              currentObj.boxtatus = response.data;
            }
          })
          .catch(function (error) {
            // currentObj.output2 = error;
            console.warn(error);
          });
        // this.caltime();
      }
    },
    async getDroneData() {
      if (this.droneid != "") {
        let currentObj = this;
        // console.log("Getting Data of Drone ID : ", this.droneid);
        // console.log("call : " + this.apiServer + "/drone/v2/status/" + this.datashowing.droneid);
        // this.ping.sendtime = new Date().getTime();
        await this.axios
          .get(this.realTimeServer + "/drone/v2/status/" + this.droneid)
          .then(function (response) {
            // currentObj.ping.receivetime = new Date().getTime();
            // currentObj.ping.delay = currentObj.ping.receivetime - currentObj.ping.sendtime
            if (response.status == 200) {
              currentObj.datashowing.dronedata = response.data;
              // console.log(currentObj.datashowing.dronedata);
              currentObj.datashowing.spinner = false;
            } else {
              currentObj.datashowing.spinner = true;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
            currentObj.datashowing.spinner = true;
          });
      }
    },
    async getBoxData() {
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.socket.emit("my_ping");
      this.ping.sendtime = new Date().getTime();
      // console.log('-----PING-------');
      if (this.boxid != "") {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.boxid);
        // console.log('Owner : ', currentObj.customer)
        await this.axios
          .get(this.realTimeServer + "/dbx/v2/status/" + this.boxid) // + currentObj.customer)
          .then(function (response) {
            if (response.status == 200) {
              // console.log(response.data);
              // currentObj.datashowing.boxdata = response.data;
              // console.log(currentObj.datashowing.boxdata);
              // currentObj.datashowing.spinner = false;
              // currentObj.states = response.data;
              // alert("Request Command : "+response.data.message);
            } else {
              currentObj.datashowing.spinner = true;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
            currentObj.datashowing.spinner = true;
            // alert(error);
          });
      }
    },
  },
  beforeDestroy() {
    // console.log('Clear Dashboard Interval Time....')
    clearInterval(this.interValDrone);
    clearInterval(this.interValBox);
    clearInterval(this.interValTime);
    this.socket.disconnect();
    this.control.disconnect();
  },
};
</script>
<style scoped>
.main-container-panel {
  /* background:rebeccapurple; */
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
}

.main-col {
  /* margin:5px; */
  /* border: 1px black solid; */
  /* border-radius: 10px; */
  /* background: black; */
  /* background: #f1f5f9; */
  /* color:white; */
  /* overflow: hidden; */
  /* min-height: 100px; */
  padding: 10px;
  background: green;
}

.bg-bk-font-green {
  /* background: black; */
  /* color: green !important; */
  font-size: 0.3rem;
  min-height: 40vh;
  max-height: 40vh;
  overflow: auto;
}

.card-data-right {
  margin: 10px;
  padding: 10px;
  border: 1px black solid;
  border-radius: 10px;
  background-color: whitesmoke;
  /* width: 100% !important; */
}

.card-data-value {
  margin: 2px;
  padding: 5px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
}

h2 {
  font-size: 1.2em;
}

h3 {
  width: 100%;
  font-size: 1em;
  text-align: center;
}

.contentmain {
  margin-left: 2rem;
  margin-right: 2rem;
  height: calc(100vh - 100px);
}

.card-body {
  max-height: 70vh;
  overflow-y: auto;
}

main {
  margin-top: 0px;
  padding: 1.4rem 0rem 0rem 0rem;
  background: #f1f5f9;
  /* min-height: calc(100vh - 85px); */
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  /* margin-top: 0rem; */
}

.card-single {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
  border-radius: 2px;
}

.card-single div:last-child span {
  color: var(--main-color);
  font-size: 3rem;
}

.card-single h1 {
  font-size: 1.2em;
}

.card-single div:first-child span {
  color: var(--text-grey);
  font-size: 00.8em;
}

.card-single:last-child {
  background: var(--main-color);
}

.card-single:last-child h1,
.card-single:last-child div:last-child span,
.card-single:last-child div:first-child span {
  color: #fff;
}

.recent-grid {
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 67% auto;
}

.card {
  background: #fff;
  border-radius: 5px;
}

.card-header,
.card-body {
  padding: 0.5rem 1rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 solid #f0f0f0;
  margin: 0px;
  min-height: 3.5rem;
}

.card-header button {
  background: var(--main-color);
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--main-color);
}

table {
  /* border-collapse: collapse;
   */
  position: relative;
  border-collapse: collapse;
}

thead th {
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
}

thead tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

thead td {
  font-weight: 700;
}

td {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  /* color: #222; */
}

tr td:last-child {
  display: flex;
  align-items: center;
}

td .status {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 1rem;
}

.status.red {
  background-color: red;
}

.status.green {
  background-color: green;
}

.status.yellow {
  background-color: yellow;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.7rem;
}

.info {
  display: flex;
  align-items: center;
}

.info img {
  border-radius: 50%;
  margin-right: 1rem;
}

.info h4 {
  font-size: 0.8rem;
  font-weight: 700;
  color: #222;
}

.info small {
  font-weight: 600;
  color: var(--text-grey);
}

.contact span {
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--main-color);
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  /* height: 50px; */
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background: #f0f0f0;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5em;
}

.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}

.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section {
  width: calc(100vw - 350px - 80px - 4rem);
  /* width: 545px; */
  height: 700px;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: left;
  margin-left: 41px;
  margin-right: 0px;
}

aside {
  width: 305px;
  height: 700px;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: right;
  margin-left: 1rem;
  margin-right: 41px;
}

.clearfix {
  clear: both;
  margin-bottom: 20px;
}

footer {
  width: 880px;
  height: 50px;
  background: yellow;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.logView {
  min-height: 29vh;
  max-height: 37vh;
  overflow: auto;
}

.content1 {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  /* width: 100%; */
}

.content1-no-buttom {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  padding-bottom: 0px;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  /* width: 100%; */
}

.infor-status table,
.content1 h1 {
  font-size: 0.75rem;
}

.video-panel {
  margin: 0 auto;
  width: calc(100vw - 350px - 80px - 4rem);
  height: 500px;
  color: var(--text-grey);
  text-align: center;
  /* background-color: var(--text-grey); */
}

.video-main {
  width: 100%;
  /* height: 100%; */
  height: 28vw;
  padding: 5px;
  margin: 0;
  border-radius: 10px;
  /* float: left; */
  /* width: calc(100vw - 350px - 80px - 4rem - 35%); */
  /* height: 500px; */
  /* background-color: black; */
}

.video-side {
  float: right;
  width: calc(100vw - 350px - 80px - 4rem - 74%);
  height: 500px;
  /* background-color: var(--main-color); */
  /* margin-right: 1rem; */
  overflow-y: auto;
}

.video-mini {
  /* background-color: var(--main-color); */
  width: 100%;
  height: 14vw;
  padding: 5px;
  border-radius: 10px;
  /* height: 50%; */
  /* margin-bottom: 1rem; */
  text-align: center;
}

.video-side .video-mini:last-child {
  margin-bottom: 0;
}

.emergency-btn {
  width: 100%;
  height: 70px;
  border-radius: 15px 15px;
  background-color: rgb(207, 0, 0);
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  /* margin-top: 1rem; */
}

.emergency-btn:hover {
  background-color: rgb(255, 0, 0);
  color: yellow;
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}

.approve-btn {
  width: 45%;
  margin: 5px;
  height: 70px;
  border-radius: 5px 5px;
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  /* margin-top: 1rem; */
}

.approve-btn:hover {
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}

.approve-btn-rd {
  background-color: rgb(207, 0, 0);
}

.approve-btn-rd:hover {
  background-color: rgb(255, 0, 0);
  color: yellow;
}

.approve-btn-gn {
  background-color: green;
}

.approve-btn-gn:hover {
  background-color: greenyellow;
  color: black;
}

.log-msg {
  height: 100%;
  min-height: 350px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--text-grey);
  border-radius: 10px;
  font-size: 0.3rem;
}

.test {
  font-size: 0.5rem;
}

.control-status {
  min-height: 200px;
  max-height: 350px;
}

.device-status {
  float: left;
  width: calc(50% - 1rem);
  /* max-height: 250px;
  overflow-y: auto; */
}

.device-control {
  float: right;
  width: calc(50% - 1rem);
  height: 280px;
}

.tab-device-status {
  max-height: 250px;
  overflow-y: auto;
}

.cmd-btn {
  height: 50px;
  /* width: 150px; */
  width: 100%;
  display: block;
  /* margin: 5px; */
  /* padding: 5px; */
  font-size: 0.75rem;
  border-radius: 10px;
  border-width: 1px;
}

.cmd-btn:hover {
  background-color: lightseagreen;
}

b-tab {
  font-size: 0.75rem;
}

.tab-class {
  font-size: 0.5rem;
}

.nav-tab-calss {
  font-size: 0.5rem;
}

/* @-webkit-keyframes alarm { */
@keyframes alarm {
  0%,
  49% {
    background-color: whitesmoke;
    /* border: 1px solid red; */
  }

  50%,
  100% {
    background-color: yellow;

    /* border: 1px solid red; */
  }
}

/* @-webkit-keyframes processing { */
@keyframes processing {
  0%,
  49% {
    background-color: whitesmoke;
    /* border: 1px solid red; */
  }

  50%,
  100% {
    background-color: green;

    /* border: 1px solid red; */
  }
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.green {
  background-color: green;
  color: white;
}

.value-moving {
  /* margin: 2px; */
  /* background-color: yellow; */
  color: red;
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: alarm 1s infinite;
  /* Safari 4+ */
  -moz-animation: alarm 1s infinite;
  /* Fx 5+ */
  -o-animation: alarm 1s infinite;
  /* Opera 12+ */
  animation: alarm 1s infinite;
  /* IE 10+, Fx 29+ */
}

.value-processing {
  /* margin: 2px; */
  /* background-color: yellow; */
  /* color: red; */
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: processing 1s infinite;
  /* Safari 4+ */
  -moz-animation: processing 1s infinite;
  /* Fx 5+ */
  -o-animation: processing 1s infinite;
  /* Opera 12+ */
  animation: processing 1s infinite;
  /* IE 10+, Fx 29+ */
}

.card-data-status {
  max-height: 50vh;
  height: 35vh;
}

.sideInfo {
  width: 100% !important;
  margin: 0;
  padding: 0;
  /* max-height: 21vw; */
}

.detailStatus {
  max-height: calc(28vw - 170px);
  /* max-height: 38vh; */
  width: 100% !important;
  padding: 0px;
}

@media only screen and (max-width: 560px) {
  .video-main {
    height: 25vh !important;
    /* display: none; */
  }

  .video-mini {
    height: 25vh !important;
    /* display: none; */
  }

  .detailStatus,
  .sideInfo {
    max-height: 100%;
  }

  .cmd-btn {
    width: 100% !important;
    display: block;
  }

  .card-data-value,
  .card-data-right {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
