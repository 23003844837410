<template>
  <div>
    <div class="main-content" style="background: var(--color-dark)">
      <header1
        enableOnlineStatus
        :lastUpdate="deviceStatus.time.value"
        enableStatus
        :deviceInfo="customerAssets.devices[pageRanderInfo.deviceIndex]"
      ></header1>
      <b-container fluid>
        <b-row align-h="center">
          <b-col class="test1" sm="3">
            <b-container fluid class="left1-container">
              <b-row class="card-data-value" align-h="center">
                <b-col>
                  <Gauge
                    :pitch="deviceStatus.system.data.attitude_pitch.value"
                    :roll="deviceStatus.system.data.attitude_roll.value"
                  />
                </b-col>
                <b-col>
                  <compass :head="deviceStatus.system.data.heading.value" />
                </b-col>
              </b-row>
              <b-row align-h="center"> </b-row>
              <b-container fluid>
                <b-row
                  align-h="between"
                  align-v="stretch"
                  style="margin-top: 10px"
                >
                  <b-col class="card-data-value" sm="5">
                    <div class="icon-wrapper">
                      <span class="las la-plane"></span>
                      <div>
                        <h4>
                          {{
                            customerAssets.devices[pageRanderInfo.deviceIndex]
                              .name
                          }}
                        </h4>
                        <small
                          >Device ID :
                          {{
                            customerAssets.devices[pageRanderInfo.deviceIndex]
                              .aid
                          }}</small
                        >
                      </div>
                    </div>
                  </b-col>
                  <b-col class="card-data-value" sm="6">
                    <div class="icon-wrapper">
                      <span class="las la-sync"></span>
                      <div>
                        <h4>{{ showTime(deviceStatus.time.value) }}</h4>
                        <small>
                          Updated :
                          {{ showDate(deviceStatus.time.value) }}</small
                        >
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="card-data-value" sm="5">
                    <div class="icon-wrapper">
                      <span class="las la-wifi"></span>
                      <div>
                        <h4
                          :class="[
                            deviceStatus.system.data.drone_active.value
                              ? 'card-data-value green'
                              : 'card-data-value red',
                            'card-data-value',
                          ]"
                        >
                          {{
                            deviceStatus.system.data.drone_active.value
                              ? "Connected"
                              : " Disconnected"
                          }}
                        </h4>
                        <small>Connection Status</small>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="card-data-value" sm="6">
                    <div class="icon-wrapper">
                      <span class="las la-plane-departure"></span>
                      <div>
                        <h4>
                          {{
                            Math.floor(
                              deviceStatus.task.data.flight_time.value / 60
                            )
                              .toString()
                              .padStart(2, "0")
                          }}
                          :
                          {{
                            Math.floor(
                              deviceStatus.task.data.flight_time.value % 60
                            )
                              .toString()
                              .padStart(2, "0")
                          }}
                          Min.
                        </h4>
                        <small>
                          Take-off :
                          {{
                            showTime(deviceStatus.task.data.takeoff_time.value)
                          }}</small
                        >
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="card-data-value" sm="5">
                    <div class="icon-wrapper">
                      <span class="las la-exclamation-circle"></span>
                      <div>
                        <h4
                          :class="[
                            !deviceStatus.system.data.armed.value
                              ? 'card-data-value green'
                              : 'card-data-value red',
                            'card-data-value',
                          ]"
                        >
                          {{
                            deviceStatus.system.data.armed.value
                              ? "ARMED"
                              : " Disarmed"
                          }}
                        </h4>
                        <small>
                          Mode :
                          {{
                            deviceStatus.system.data.flight_mode.value
                          }}</small
                        >
                      </div>
                    </div>
                  </b-col>
                  <b-col class="card-data-value" sm="6">
                    <div class="icon-wrapper">
                      <span class="las la-running"></span>
                      <div>
                        <h4>{{ deviceStatus.task.data.task_state.value }}</h4>
                        <small> Task State </small>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="card-data-value" sm="5">
                    <div class="icon-wrapper">
                      <span class="las la-level-up-alt"></span>
                      <div>
                        <h4>
                          {{
                            deviceStatus.system.data.position_altitude_rel.value.toFixed(
                              2
                            )
                          }}
                          Rel.m
                        </h4>
                        <small>
                          {{
                            deviceStatus.system.data.position_altitude_abs.value.toFixed(
                              2
                            )
                          }}
                          Abs.m
                        </small>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="card-data-value" sm="6">
                    <div class="icon-wrapper">
                      <span class="las la-tasks"></span>
                      <div>
                        <h4>
                          {{
                            (
                              (deviceStatus.task.data.mission_progress_current
                                .value /
                                deviceStatus.task.data.mission_progress_total
                                  .value) *
                              100
                            ).toFixed(2)
                          }}
                          %
                          <small
                            >(
                            {{
                              deviceStatus.task.data.mission_progress_current
                                .value
                            }}
                            of
                            {{
                              deviceStatus.task.data.mission_progress_total
                                .value
                            }})</small
                          >
                        </h4>
                        <small>
                          Task ID : {{ deviceStatus.task.data.task_id.value }}
                        </small>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col class="card-data-value" sm="5">
                    <div class="icon-wrapper">
                      <span class="las la-map-marked"></span>
                      <div>
                        <h4
                          :class="[
                            !deviceStatus.system.data['IR-lock'].value.includes(
                              'not'
                            )
                              ? 'card-data-value green'
                              : 'card-data-value red',
                            'card-data-value',
                          ]"
                        >
                          {{
                            !deviceStatus.system.data["IR-lock"].value.includes(
                              "not"
                            )
                              ? "DETECTED"
                              : " UNDETECTED"
                          }}
                        </h4>
                        <small> Beacon Status </small>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="card-data-value" sm="6">
                    <div class="icon-wrapper">
                      <span class="las la-battery-three-quarters"></span>
                      <div>
                        <h4>
                          {{
                            deviceStatus.system.data.battery_voltage.value.toFixed(
                              2
                            )
                          }}
                          v
                          <small
                            >(
                            {{
                              deviceStatus.system.data.battery_percent.value.toFixed(
                                0
                              )
                            }}% )</small
                          >
                        </h4>
                        <small> Battery Voltage </small>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-container>
          </b-col>
          <b-col class="center1-container" sm="6" style="padding: 0px"
            ><iframe
              v-if="camData"
              class="video-main"
              :src="videoServer + camID"
              frameborder="0"
              playsinline
              allowfullscreen
              controls="false"
            ></iframe>
          </b-col>
          <b-col class="test1" sm="3">
            <b-container fluid>
              <b-row align-h="center" class="">
                <div ref="map" class="map-panel"></div>
              </b-row>
              <b-row align-h="center">
                <button
                  class="emergency-btn"
                  type="button"
                  @click="sendCommand(cmd_uav.abort)"
                >
                  <b-row align-v="center" align-h="center">
                    ABORT
                    <span
                      style="font-size: 2.5rem"
                      class="las la-times-circle"
                    ></span>
                  </b-row>
                </button>
                <button
                  class="emergency-btn"
                  type="button"
                  @click="sendCommand(cmd_uav.nextwp)"
                >
                  <b-row align-v="center" align-h="center">
                    NEXT WAYPOINT
                    <span
                      style="font-size: 2.5rem"
                      class="las la-exclamation-triangle"
                    ></span>
                  </b-row>
                  <b-row align-v="center" align-h="center">
                    TEST BTN
                    <span
                      style="font-size: 2.5rem"
                      class="las la-exclamation-triangle"
                    ></span>
                  </b-row>
                </button>
                <button
                  class="emergency-btn"
                  type="button"
                  @click="sendCommand(cmd_uav.orbit)"
                >
                  <b-row align-v="center" align-h="center">
                    ORBIT ACT
                    <span
                      style="font-size: 2.5rem"
                      class="las la-plane-arrival"
                    ></span>
                  </b-row>
                </button>
                <button
                  class="cmdPanel-btn"
                  type="button"
                  @click="showControlPanel"
                >
                  <b-row align-v="center" align-h="center">
                    {{ isPanelActive ? "HIDE" : "SHOW" }}
                    <span
                      style="font-size: 2.5rem"
                      class="las la-gamepad"
                    ></span>
                  </b-row>
                </button>
                <button
                  class="cmdPanel-btn"
                  type="button"
                  @click="sendCommand(cmd_uav.pause)"
                >
                  <b-row align-v="center" align-h="center">
                    PAUSE
                    <span
                      style="font-size: 2.5rem"
                      class="las la-pause-circle"
                    ></span>
                  </b-row>
                </button>
                <button
                  class="cmdPanel-btn"
                  type="button"
                  @click="sendCommand(cmd_uav.resume)"
                >
                  <b-row align-v="center" align-h="center">
                    RESUME
                    <span
                      style="font-size: 2.5rem"
                      class="las la-play-circle"
                    ></span>
                  </b-row>
                </button>
              </b-row>
            </b-container>
          </b-col>
          <b-col
            class="test1"
            :class="{ statusMini: isPanelActive }"
            sm="7"
            style="margin-top: 10px"
          >
            <b-container fluid="xl">
              <b-row> </b-row>
              <b-row align-h="start" class="card-data">
                <b-col
                  v-if="
                    deviceStatus.task.data.takeoff_time.value &&
                    deviceStatus.task.data.takeoff_time.value -
                      deviceStatus.time.value >
                      0
                  "
                  sm="12"
                  class="card-data-value2 value-moving"
                  ><h2>
                    Take-off in
                    {{
                      (
                        (deviceStatus.task.data.takeoff_time.value -
                          deviceStatus.time.value) /
                        1000
                      ).toFixed(0)
                    }}
                    Second
                  </h2></b-col
                >
                <b-col
                  :md="statusStyle"
                  v-for="item in deviceStatus.system.data"
                  :key="
                    item.key + (Math.random() + 1).toString(36).substring(7)
                  "
                >
                  <CardValue
                    :name="item.key"
                    :value="item.value + ' ' + item.unit"
                    class="card-data-value-test"
                  />
                </b-col>
                <b-col
                  :md="statusStyle"
                  v-for="item in deviceStatus.camera.data"
                  :key="
                    item.key + (Math.random() + 1).toString(36).substring(7)
                  "
                >
                  <CardValue
                    :name="item.key"
                    :value="item.value + ' ' + item.unit"
                    class="card-data-value-test"
                  />
                </b-col>
                <b-col
                  :md="statusStyle"
                  v-for="item in deviceStatus.uncertainty.data"
                  :key="
                    item.key + (Math.random() + 1).toString(36).substring(7)
                  "
                >
                  <CardValue
                    :name="item.key"
                    :value="item.value + ' ' + item.unit"
                    class="card-data-value-test"
                  />
                </b-col>
                <b-col
                  :md="statusStyle"
                  v-for="item in deviceStatus.information.data"
                  :key="
                    item.key + (Math.random() + 1).toString(36).substring(7)
                  "
                >
                  <CardValue
                    :name="item.key"
                    :value="item.value + ' ' + item.unit"
                    class="card-data-value-test"
                  />
                </b-col>
                <b-col
                  :md="statusStyle"
                  v-for="item in deviceStatus.task.data"
                  :key="
                    item.key + (Math.random() + 1).toString(36).substring(7)
                  "
                >
                  <CardValue
                    :name="item.key"
                    :value="item.value + ' ' + item.unit"
                    class="card-data-value-test"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col class="test1" v-if="isPanelActive">
            <b-container fluid>
              <b-row> </b-row>
              <b-tabs
                card
                dark
                nav-wrapper-class="w-15"
                class="uavControlPanel card-data-value"
              >
                <b-tab title="Payload Control" class="detailStatus" active>
                  <b-container fluid>
                    <b-row align-v="center">
                      <b-col sm="2" class="card-frame">
                        <b-button
                          class="zoomControlBtn"
                          @click="sendCommand(cmd_uav.wide)"
                          ><span class="las la-expand"></span
                        ></b-button>
                        <b-button
                          class="zoomControlBtn"
                          @mousedown="setIntervalCommand(cmd_uav.zoomInc)"
                          @mouseup="clearIntervalCommand"
                          @mouseout="clearIntervalCommand"
                          ><span class="las la-search-plus"></span
                        ></b-button>
                        <b-button
                          class="zoomControlBtn"
                          @mousedown="setIntervalCommand(cmd_uav.zoomDec)"
                          @mouseup="clearIntervalCommand"
                          @mouseout="clearIntervalCommand"
                          ><span class="las la-search-minus"></span
                        ></b-button>
                        <b-button
                          class="zoomControlBtn"
                          @click="sendCommand(cmd_uav.infra)"
                          ><span class="las la-compress"></span
                        ></b-button>
                      </b-col>
                      <b-col sm="4">
                        <b-row align-h="center">
                          <b-form-group>
                            <b-form-radio-group
                              id="btn-radios-1"
                              v-model="selectedPayloadMode"
                              :options="payloadModeOptions"
                              name="radios-btn-default"
                              buttons
                              v-on:change="cameraModeChange"
                            >
                            </b-form-radio-group>
                          </b-form-group>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            v-if="selectedPayloadMode === 'Photo'"
                            variant="outline-danger"
                            class="shutterControlBtn"
                            @click="sendCommand(cmd_uav.capture)"
                          ></b-button>
                          <b-button
                            v-if="
                              selectedPayloadMode === 'Video' && !isRecording
                            "
                            variant="outline-danger"
                            class="shutterControlBtn"
                            @click="videoCommand(cmd_uav.start_record)"
                          ></b-button>
                          <b-button
                            v-if="
                              selectedPayloadMode === 'Video' && isRecording
                            "
                            variant="danger"
                            class="shutterStopControlBtn"
                            @click="videoCommand(cmd_uav.stop_record)"
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button-group>
                            <b-button
                              class="menualPalyloadBtn"
                              @click="sendCommand(cmd_uav.track_point)"
                              ><span class="las la-draw-polygon"></span
                            ></b-button>
                            <b-button
                              class="menualPalyloadBtn"
                              @click="sendCommand(cmd_uav.track_stop)"
                              ><span class="las la-square-full"></span
                            ></b-button>
                            <b-button
                              class="menualPalyloadBtn"
                              @click="sendCommand(cmd_uav.track_rectangle)"
                              ><span class="las la-vector-square"></span
                            ></b-button>
                          </b-button-group>
                        </b-row>
                      </b-col>
                      <b-col sm="4" class="card-frame">
                        <b-row align-h="center">
                          <b-button
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.cam_pitch_up)
                            "
                            @mouseup="clearIntervalCommand"
                            @mouseout="clearIntervalCommand"
                            ><span class="las la-chevron-up"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.cam_yaw_left)
                            "
                            @mouseup="clearIntervalCommand"
                            @mouseout="clearIntervalCommand"
                            ><span class="las la-chevron-left"></span
                          ></b-button>
                          <b-button
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.cam_yaw_right)
                            "
                            @mouseup="clearIntervalCommand"
                            @mouseout="clearIntervalCommand"
                            ><span class="las la-chevron-right"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.cam_pitch_down)
                            "
                            @mouseup="clearIntervalCommand"
                            @mouseout="clearIntervalCommand"
                            ><span class="las la-chevron-down"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button-group>
                            <b-button
                              class="menualPalyloadBtn noMargin"
                              @click="sendCommand(cmd_uav.cam_pitch_origin)"
                              ><span class="las la-arrows-alt-v"></span
                            ></b-button>
                            <b-button
                              class="menualPalyloadBtn noMargin"
                              @click="sendCommand(cmd_uav.cam_yaw_origin)"
                              ><span class="las la-arrows-alt-h"></span
                            ></b-button>
                          </b-button-group>
                        </b-row>
                      </b-col>
                      <b-col sm="2">
                        <b-button
                          class="zoomControlBtn"
                          @click="sendCommand(cmd_uav.focus_auto)"
                          >Auto</b-button
                        >
                        <b-button
                          class="zoomControlBtn"
                          @mousedown="setIntervalCommand(cmd_uav.focus_Inc)"
                          @mouseup="clearIntervalCommand"
                          @mouseout="clearIntervalCommand"
                          >Focus+</b-button
                        >
                        <b-button
                          class="zoomControlBtn"
                          @mousedown="setIntervalCommand(cmd_uav.focus_Dec)"
                          @mouseup="clearIntervalCommand"
                          @mouseout="clearIntervalCommand"
                          >Focus-</b-button
                        >
                        <b-row align-h="center">
                          <b-button-group>
                            <b-button
                              class="menualPalyloadBtn noMargin"
                              @click="sendCommand(cmd_uav.laser_on)"
                              ><span class="las la-moon"></span
                            ></b-button>
                            <b-button
                              class="menualPalyloadBtn noMargin"
                              @click="sendCommand(cmd_uav.laser_off)"
                              ><span class="las la-sun"></span
                            ></b-button>
                          </b-button-group>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
                <b-tab title="Payload Mode" class="detailStatus">
                  <div class="btn-layout">
                    <b-button
                      class="btn-mode"
                      v-for="item in cmd_mode"
                      :key="item.name"
                      variant="outline-secondary"
                      @click="sendCommand(item)"
                      >{{ item.name }}</b-button
                    >
                    <b-button class="btn-mode" :variant="(joyStickControl)?'outline-secondary':'success'" :disabled="joyStickControl" @click="enableJoystick">Connect Joystick</b-button>
                    <b-button class="btn-mode" :variant="(!joyStickControl)?'outline-secondary':'danger'" :disabled="!joyStickControl" @click="disableJoystick">Disconnect Joystick</b-button>
                  </div>
                </b-tab>
                <b-tab title="Drone Manual Control" class="detailStatus">
                  <b-container fluid align-v="around">
                    <b-row align-v="center">
                      <b-col sm="4" class="card-frame">
                        <b-row align-h="center">Altitude and Yaw Control</b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_upward)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-arrow-up"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_yaw_ccw)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-undo-alt"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_yaw_cw)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-redo-alt"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_downward)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-arrow-down"></span
                          ></b-button>
                        </b-row>
                      </b-col>
                      <b-col sm="1"></b-col>

                      <b-col
                        sm="2"
                        class="card-frame"
                        align-h="center"
                        align-v="center"
                      >
                        <b-row align-h="center">Control Function</b-row>
                        <b-button
                          :disabled="UAVmenualControl"
                          variant="outline-danger"
                          class="menualPalyloadBtn3"
                          @click="enableControlFn"
                          >ENABLE</b-button
                        >
                        <b-button
                          :disabled="!UAVmenualControl"
                          variant="outline-success"
                          class="menualPalyloadBtn3"
                          @click="disableControlFn"
                          >DISABLE</b-button
                        >
                      </b-col>
                      <b-col sm="1"></b-col>

                      <b-col sm="4" class="card-frame">
                        <b-row align-h="center">Position Control</b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_forward_left)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-up tranfrom-45"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_forward)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-up"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_forward_right)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-up tranfrom45"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_left)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-left"></span
                          ></b-button>
                          <b-button disabled class="menualPalyloadBtn2"
                            ><span class="las la-circle"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_right)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-right"></span
                          ></b-button>
                        </b-row>
                        <b-row align-h="center">
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_backward_left)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-down tranfrom45"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(cmd_uav.offboard_backward)
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span class="las la-chevron-down"></span
                          ></b-button>
                          <b-button
                            :disabled="!UAVmenualControl"
                            class="menualPalyloadBtn2"
                            @mousedown="
                              setIntervalCommand(
                                cmd_uav.offboard_backward_right
                              )
                            "
                            @mouseup="clearIntervalUAV_ControlCommand"
                            @mouseout="clearIntervalUAV_ControlCommand"
                            ><span
                              class="las la-chevron-down tranfrom-45"
                            ></span
                          ></b-button>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
              </b-tabs>
            </b-container>
          </b-col>
          <b-col
            class="test1"
            :class="{ statusMini: isPanelActive }"
            sm="5"
            style="margin-top: 10px"
          >
            <b-container fluid>
              <b-row align-h="center" class="card-data-value">
                <small>Log Message</small>
                <div class="log-msg">
                  <table>
                    <tr
                      class="test"
                      v-for="item in logmsg"
                      :key="
                        item.time +
                        (Math.random() + 1).toString(36).substring(7)
                      "
                    >
                      <td style="display: hidden">
                        {{ item.date }}
                      </td>
                      <td>
                        {{ item.time }}
                      </td>
                      <td>
                        {{ item.msg }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import header1 from "../components/header/Header_Normal.vue";
import Gauge from "../components/uavGauge.vue";
import compass from "../components/CompassGauge.vue";
import swal from "sweetalert2";
import CardValue from "../components/CardValue.vue";
export default {
  name: "Dashboard",
  components: {
    header1,
    Gauge,
    compass,
    CardValue,
  },
  props: {
    serverTime: Object,
  },
  data() {
    return {
      pageRanderInfo: {
        deviceID: -1,
        deviceIndex: -1,
        siteIndex: -1,
        deviceKey: "",
      },
      isInitialPage: true,
      camID: "0",
      isRefreshID: false,
      isLoadedMissionData: false,
      missionDataID: 0,
      taskInfo: {},
      missionData: {},
      missionMarker: [],
      missionPath: null,
      statusData: [],
      deviceStatus: {
        time: { key: "Last Update", value: 0.0 },
        Sim_Mode: { value: true },
        system: {
          name: "Drone Status",
          data: {
            drone_active: {
              key: "Connection Status",
              value: true,
              unit: "",
            },
            armed: {
              key: "Arm State",
              value: false,
              unit: "",
            },
            "IR-lock": {
              key: "IR-lock",
              value: "not found",
              unit: "",
            },
            attitude_roll: {
              key: "Roll",
              value: -0.17,
              unit: "deg",
            },
            attitude_pitch: {
              key: "Pitch",
              value: 0.63,
              unit: "deg",
            },
            attitude_yaw: {
              key: "Yaw",
              value: 86.61,
              unit: "deg",
            },
            battery_id: {
              key: "Batt.ID",
              value: 0,
              unit: "",
            },
            battery_percent: {
              key: "Battery",
              value: 100.0,
              unit: "%",
            },
            battery_voltage: {
              key: "Battery",
              value: 16.2,
              unit: "v",
            },
            distance_sensor: {
              key: "Distance sensor",
              value: 103.0,
              unit: "m",
            },
            velocity_ned_x: {
              key: "north_m_s",
              value: 0.0,
              unit: "m_s",
            },
            velocity_ned_y: {
              key: "east_m_s",
              value: 0.0,
              unit: "m_s",
            },
            velocity_ned_z: {
              key: "down_m_s",
              value: 0.0,
              unit: "m_s",
            },
            horizontal_speed: {
              key: "Horizontal speed",
              value: 0.0,
              unit: "m_s",
            },
            vertical_speed: {
              key: "Vertical speed",
              value: 0.0,
              unit: "m_s",
            },
            flight_mode: {
              key: "Flight mode",
              value: "HOLD",
              unit: "",
            },
            gps_fix_type: {
              key: "Gps fixed",
              value: "FIX_3D",
              unit: "",
            },
            gps_num_satellites: {
              key: "Satellites",
              value: 10,
              unit: "unit",
            },
            heading: {
              key: "Heading",
              value: 86.61,
              unit: "deg",
            },
            landed_state: {
              key: "State",
              value: "ON_GROUND",
              unit: "",
            },
            position_lat: {
              key: "Pos lat",
              value: 55.666516,
              unit: "deg",
            },
            position_long: {
              key: "Pos lng",
              value: 12.5426675,
              unit: "deg",
            },
            position_altitude_rel: {
              key: "Alt rel",
              value: 0.066,
              unit: "m",
            },
            position_altitude_abs: {
              key: "Alt abs",
              value: 488.263,
              unit: "m",
            },
            pressure_altitude: {
              key: "Alt pressure",
              value: 488.263,
              unit: "m",
            },
            geodetic_altitude: {
              key: "Alt geodetic",
              value: 488.283,
              unit: "m",
            },
            height: {
              key: "Height",
              value: 0.066,
              unit: "m",
            },
            rc_available: {
              key: "Rc available",
              value: true,
              unit: "",
            },
            rc_signal_strength_percent: {
              key: "Signal strength",
              value: "nan",
              unit: "%",
            },
            status_text: {
              key: "Status text",
              value: "",
              unit: "",
            },
            status_type: {
              key: "Status type",
              value: "DEBUG",
              unit: "",
            },
            timestamp: {
              key: "Timestamp",
              value: 1665999014.791479,
              unit: "sec",
            },
          },
        },
        camera: {
          name: "Camera Status",
          data: {
            camera_active: {
              key: "Connection status",
              value: false,
              unit: "",
            },
            zoom_level: {
              key: "zoom",
              value: 1,
              unit: "x",
            },
            camera_pitch: {
              key: "pitch",
              value: 0,
              unit: "deg",
            },
            camera_yaw: {
              key: "yaw",
              value: 0,
              unit: "deg",
            },
          },
        },
        uncertainty: {
          name: "Uncertainty Data",
          data: {
            horizontal_accuracy: {
              key: "HDOP",
              value: 0.0,
              unit: "",
            },
            vertical_accuracy: {
              key: "VDOP",
              value: 0.0,
              unit: "",
            },
            barometric_altitude_accuracy: {
              key: "Baro Acc.",
              value: 0,
              unit: "m",
            },
            speed_accuracy: {
              key: "Speed Acc.",
              value: 0,
              unit: "m_s",
            },
            horizontal_uncertainty: {
              key: "Pos uncertain.",
              value: 1.0,
              unit: "m",
            },
            vertical_uncertainty: {
              key: "Alt uncertain.",
              value: 1.0,
              unit: "m",
            },
            velocity_uncertainty: {
              key: "Speed uncertain.",
              value: 0.25,
              unit: "m_s",
            },
            heading_uncertainty: {
              key: "Heading uncertain.",
              value: 0.0,
              unit: "deg",
            },
          },
        },
        information: {
          name: "Information",
          data: {
            flight_sw: {
              key: "Flight S/W Ver.",
              value: "1.13.0",
              unit: "",
            },
            flight_sw_vendor: {
              key: "Flight S/W vendor",
              value: "0.0.0",
              unit: "",
            },
            os_sw: {
              key: "OS S/W Ver.",
              value: "5.15.0",
              unit: "",
            },
            flight_sw_git_hash: {
              key: "Flight S/W git hash",
              value: "3c6b72c33\\x00",
              unit: "",
            },
            os_sw_git_hash: {
              key: "OS S/W git hash",
              value: "c5c7d2b4f26f52f\\x00",
              unit: "",
            },
            vendor_id: {
              key: "ID of the board vendor",
              value: 0,
              unit: "",
            },
            vendor_name: {
              key: "Name of the vendor",
              value: "undefined",
              unit: "",
            },
            product_id: {
              key: "ID of the product",
              value: 0,
              unit: "",
            },
            product_name: {
              key: "Name of the product",
              value: "undefined",
              unit: "",
            },
          },
        },
        task: {
          name: "Task Status",
          data: {
            task_state: {
              key: "Task state",
              value: "request_mission",
              unit: "",
            },
            task_id: {
              key: "Task ID",
              value: "None",
              unit: "",
            },
            drone_id: {
              key: "Drone ID",
              value: "1",
              unit: "",
            },
            station_id: {
              key: "Station ID",
              value: "2",
              unit: "",
            },
            operationalIntentId: {
              key: "OI",
              value: "",
              unit: "",
            },
            takeoff_time: {
              key: "Takeoff time",
              value: 0,
              unit: "sec",
            },
            flight_time: {
              key: "Flight time",
              value: 0,
              unit: "sec",
            },
            mission_progress_current: {
              key: "Current waypoint",
              value: 0,
              unit: "unit",
            },
            mission_progress_total: {
              key: "Total waypoint",
              value: 0,
              unit: "unit",
            },
          },
        },
        device_id: { key: "Box ID", value: "--" },
      },
      path: [],
      socket: null,
      control: null,
      webAPI: this.$store.state.webAPI,
      deviceKey: this.$store.state.deviceKey,
      apiKey: this.$store.state.apiKey,
      sioServer: this.$store.state.sioServer,
      customerID: this.$store.state.customerID,
      userName: this.$store.state.user,
      // selectedAsset: this.$store.state.selectedAsset,
      customerAssets: this.$store.state.customerAsset,
      assetList: {},
      logmsg: [],
      videoShow: false,
      vDroneID: "",
      videoServer: this.$store.state.videoServer, //        "https://streaming.dronebox-dev.arv.co.th:/WebRTCAppEE/play.html?name=DroneSim-9",
      //////////////////////////////////////////////////////////////////
      cmd_emer: {
        name: "Emergency",
        data: {
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          type: 10000,
          command: 1,
          data: 0,
        },
      },
      cmd_uav: {
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        dummyCMD:{ name: "dummyCMD", data: { type: 0, command: 0, data: 0 } },
        emergency: {
          name: "Emergency",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 10000, command: 1150, data: 0 },
        },
        nextwp: {
          name: "Nextwp",
          data: {
            // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 10000,
            command: 1044,
            data: 0,
          },
        },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        abort: { name: "Abort", data: { type: 10000, command: 1050, data: 0 } },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        land: { name: "Land", data: { type: 10000, command: 1060, data: 0 } },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        pause: { name: "Pause", data: { type: 10000, command: 1041, data: 0 } },
        resume: {
          name: "Resume",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 10000, command: 1042, data: 0 },
        },
        capture: {
          name: "Capture",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2000, data: 0 },
        },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this> 
        wide: { name: "Wide", data: { type: 20000, command: 2101, data: 0 } },
        zoomInc: {
          name: "Zoom+",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2102, data: 0 },
        },
        zoomDec: {
          name: "Zoom-",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2103, data: 0 },
        },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        infra: { name: "Infra", data: { type: 20000, command: 2104, data: 0 } },
        cam_pitch_up: {
          name: "Cam Pitch up",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2212, data: 0 },
        },
        cam_pitch_down: {
          name: "Cam Pitch down",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2213, data: 0 },
        },
        cam_yaw_left: {
          name: "Cam Yaw left",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2223, data: 0 },
        },
        cam_yaw_right: {
          name: "Cam Yaw right",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2222, data: 0 },
        },
        cam_pitch_origin: {
          name: "Cam Pitch origin",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2211, data: 0 },
        },
        cam_yaw_origin: {
          name: "Cam Yaw origin",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2221, data: 0 },
        },
        focus_auto: {
          name: "Focus auto",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2300, data: 0 },
        },
        focus_Inc: {
          name: "Focus +",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2302, data: 0 },
        },
        focus_Dec: {
          name: "Focus -",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2303, data: 0 },
        },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        photo: { name: "Photo", data: { type: 20000, command: 2400, data: 0 } },
        // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
        video: { name: "Video", data: { type: 20000, command: 2401, data: 0 } },
        start_record: {
          name: "Start record",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2001, data: 0 },
        },
        stop_record: {
          name: "Stop record",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2002, data: 0 },
        },
        laser_on: {
          name: "Laser on",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2500, data: 0 },
        },
        laser_off: {
          name: "Laser off",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2501, data: 0 },
        },
        track_point: {
          name: "Track point",
          data: {
            // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 20000,
            command: 2600,
            data: { point_x: 0.5, point_y: 0.5, radius: 0.2 },
          },
        },
        track_rectangle: {
          name: "Track rectangle",
          data: {
            // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 20000,
            command: 2601,
            data: {
              top_left_x: 0.4,
              top_left_y: 0.4,
              bot_right_x: 0.6,
              bot_right_y: 0.6,
            },
          },
        },
        track_stop: {
          name: "Track stop",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2602, data: 0 },
        },
        offboard_enable: {
          name: "Offboard enable",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 10000, command: 1071, data: 0 },
        },
        offboard_disable: {
          name: "Offboard disable",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 10000, command: 1072, data: 0 },
        },
        velocity_body: {
          name: "Velocity body",
          data: {
            // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 40000,
            command: 4100,
            data: {
              forward_m_s: 0,
              right_m_s: 0,
              down_m_s: 0,
              yawspeed_deg_s: 0,
            },
          },
        },
        velocity_body_forward: {
          name: "Velocity body forward",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4110, data: { forward_m_s: 0.5 } }, //[down,forward,right,yaw]
        },
        velocity_body_right: {
          name: "Velocity body right",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4120, data: { right_m_s: 0.5 } },
        },
        velocity_body_down: {
          name: "Velocity body down",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4130, data: { down_m_s: -0.5 } },
        },
        velocity_body_yaw: {
          name: "Velocity body yaw",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4140, data: { yawspeed_deg_s: 10 } },
        },
        velocity_body_stop: {
          name: "Velocity body stop",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4150, data: 0 },
        },
        offboard_forward: {
          name: "Offboard forward",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4261, data: 0 },
        },
        offboard_backward: {
          name: "Offboard backward",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4262, data: 0 },
        },
        offboard_right: {
          name: "Offboard right",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4271, data: 0 },
        },
        offboard_left: {
          name: "Offboard left",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4272, data: 0 },
        },
        offboard_yaw_cw: {
          name: "Offboard yaw cw",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4281, data: 0 },
        },
        offboard_yaw_ccw: {
          name: "Offboard yaw ccw",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4282, data: 0 },
        },
        offboard_forward_left: {
          name: "Offboard forward_left",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4263, data: 0 },
        },
        offboard_forward_right: {
          name: "Offboard forward_right",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4264, data: 0 },
        },
        offboard_backward_left: {
          name: "Offboard backward_left",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4265, data: 0 },
        },
        offboard_backward_right: {
          name: "Offboard backward_right",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4266, data: 0 },
        },
        offboard_upward: {
          name: "Offboard upward",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4291, data: 0 },
        },
        offboard_downward: {
          name: "Offboard downward",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4292, data: 0 },
        },
        offboard_stop: {
          name: "Offboard stop",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 40000, command: 4250, data: 0 },
        },
        orbit: {
          name: "Orbit",
          data: {
            // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
            type: 10000,
            command: 1170,
            data: {
              radius_m: 30,
              velocity_ms: 1,
              yaw_behavior: 0,
              latitude_deg: 12.99443,
              longitude_deg: 101.44716,
              absolute_altitude_m: 70,
            },
          },
        },
      },
      cmd_mode: {
        EO_IRwhitehot: {
          name: "set EO + IR whitehot pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2403, data: 0 },
        },
        EO_IRblackhot: {
          name: "set EO + IR black hot pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2404, data: 0 },
        },
        EO_IRpseudo: {
          name: "set EO + IR Pseudo color pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2405, data: 0 },
        },
        EO_only: {
          name: "set EO only",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2402, data: 0 },
        },
        IRwhitehot: {
          name: "set IR whitehot only",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2409, data: 0 },
        },
        IRblackhot: {
          name: "set IR blackhot only",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2410, data: 0 },
        },
        IRpseudo: {
          name: "set IR pseudo color only",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2411, data: 0 },
        },
        IRwhitehot_EO: {
          name: "set IR whitehot + EO pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2406, data: 0 },
        },
        IRblackhot_EO: {
          name: "set IR blackhot + EO pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2407, data: 0 },
        },
        IRpseudo_EO: {
          name: "set IR Pseudo color + EO pip",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2408, data: 0 },
        },

        laser_on: {
          name: "Laser on",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2500, data: 0 },
        },
        laser_off: {
          name: "Laser off",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2501, data: 0 },
        },
        OSD_all_on: {
          name: "all OSD on",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2502, data: 0 },
        },
        OSD_all_off: {
          name: "all OSD off",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2503, data: 0 },
        },

        Payload_connect: {
          name: "Camera Connect",
          // deepcode ignore VuePropsDeclAssignment: <please specify a reason of ignoring this>
          data: { type: 20000, command: 2701, data: 0 },
        },
      },
      //////////////////////////////////////////////////////////////////
      selectedType: "All",
      optionsType: [
        { text: "Station Only", value: "Station" },
        { text: "Drone Only", value: "Drone" },
        { text: "All", value: "All" },
      ],
      boxDataStatus: {},
      // statusData: [],
      realTimeServer: this.$store.state.apiRealtimeServe,
      boxid: "BoxSim9", //this.$store.state.selectedBoxID,
      droneid: "DroneSim9", //this.$store.state.selectedDroneID,
      timeNow: { time: null, date: null },
      datashowing: {
        boxdata: {},
        dronedata: {
          pitch: 0.0,
          roll: 0.0,
          heading: 0.0,
          altitude: 0.0,
          speed: 0.0,
          hdop: 0.0,
          gpsSat: 0,
          battPercent: 0.0,
          battVoltage: 0.0,
          rssi: 0.0,
          operatingMode: "Auto",
          arm: false,
          time: 0,
        },
      },

      // socket: null,
      filter: "",
      ping: {
        sendtime: 0,
        receivetime: 0,
        delay: 0,
      },
      servertime: { date: null, time: null },
      servermessage: [],
      isPanelActive: false,
      statusStyle: 4,
      selectedPayloadMode: "Photo",
      isTimerEnable: false,
      intervalCommand: null,
      intervalTimer: null,
      isRecording: false,
      UAVmenualControl: false,
      payloadModeOptions: [
        { text: "Photo", value: "Photo" },
        { text: "Video", value: "Video" },
      ],
      dronestatus: {
        Altitude: 0,
        DistToHome: 0,
        EKF: "",
        Speed: 0,
        batt_voltage: 0,
        batt_percent: 0,
        MissionID: 0,
        PercentOfMission: 0,
        CurrentWP: 0,
        TotalWp: 0,
        Roll: 0,
        Pitch: 0,
        heading: 0.0,
        hdop: 0.0,
        gpsSat: 0.0,
        battPercent: 0.0,
        battVoltage: 0.0,
        rssi: 0.0,
        operatingMode: "--",
        arm: false,
        time: 0,
      },
      boxtatus: {},
      isSimulate: false,
      interValDrone: null,
      interValBox: null,
      interValTime: null,
      camData: null,
      joystick_info: {
        current:{
          "T.16000M (Vendor: 044f Product: b10a)":{
            axes:[0,0,0,0,0,0,0,0,0,0],
            buttons:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]
          },
          "TWCS Throttle (Vendor: 044f Product: b687)":{
            axes:[0,0,0,0,0,0,0,0,0,0],
            buttons:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]
          },
      },
        previous:{
          "T.16000M (Vendor: 044f Product: b10a)":{
            axes:[0,0,0,0,0,0,0,0,0,0],
            buttons:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]
          },
          "TWCS Throttle (Vendor: 044f Product: b687)":{
            axes:[0,0,0,0,0,0,0,0,0,0],
            buttons:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,]
          },
      },
        command:{}
      },
      joyType:{
        button:1,
        axes:2,
      },
      command_template:{
        joyStickID:null,
        chanel:null,
        type:null,
        rawVal:null,
        mappedCMD:null,
      },
      mappingCMD:{},
      lastCommand:0,
      calculatedFPS:false,
      fps:60,
      counterFilter:0,
      joyStickControl:false,
    };
  },
  beforeCreate() {
    // let id = this.$route.params.droneID;
    // console.log("ID: ", id);
    // if (id){
    //   console.log("ID: ", id);
    //   let key1 = this.getKey(id);
    //   console.log("Key : ",key1);
    // }
  },
  created() {
    let id = this.$route.params.droneID;
    let currentObj = this;
    console.log("ID: ", id);
    if (id) {
      console.log("ID: ", id);
      let key1 = this.getKey(id);
      // currentObj.pageRanderInfo.deviceKey = key1;
      console.log("Key : ", key1);
      currentObj.pageRanderInfo.deviceID = id;
      this.getIndex();
    } else {
      swal.fire({
        icon: "error",
        title: "Oops... ",
        text: "Something went wrong! Please try again later",
      });
    }
  },
  computed: {
    classMarker: function () {
      // console.log('Test')
      return {
        transform:
          "rotate(" + this.deviceStatus.system.data.heading.value + "deg)",
        "transform-origin": "-10px -10px",
      };
    },
  },
  mounted() {
    // let box = document.querySelector('.video-main');
    // let width = box.offsetWidth;
    // let height = box.offsetHeight;
    this.calculateFPS();
    requestAnimationFrame(this.calculateFPS);

    this.getCamData();
    // console.log({ width, height });
    //

    if (this.customerID == null) {
      this.customerID = sessionStorage.getItem("setcustomerID");
    }
    if (this.apiKey == null) {
      this.apiKey = sessionStorage.getItem("setAPIKey");
    }
    this.socket = new window.io(this.sioServer + "/drone/status", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });

    this.control = new window.io(this.sioServer + "/command", {
      auth: { deviceID: this.userName, token: this.apiKey },
      transports: ["websocket"],
    });
    this.socket.on("log", (data) => this.logHandle(data));
    this.socket.on("log_init", (data) => {
      console.log("Log initial!");
      data.forEach(this.logHandle);
    });
    this.socket.on("cache_status", (data) => {
      console.log("cache_status");
      this.onStatus(data);
    });
    this.socket.on("connect", this.onConnect);
    this.control.on("connect", this.onConnectControl);
    this.socket.on("status", (data) => this.onStatus(data));
    this.socket.on("hir_request", (data) => this.onHIR_Req(data));
    this.socket.on("hir_cancel", (data) => this.onHIR_Cancel(data));
    this.socket.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });
    this.control.on("authen", (data) => {
      console.log(data);
      sessionStorage.clear();
      this.$store.commit("setLoginState", false);
      window.location.href = "/#/";
    });
    this.map = new window.google.maps.Map(this.$refs["map"], {
      mapTypeId: "satellite",
      center: { lat: 13.932, lng: 100.573 },
      zoom: 17,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    });
    this.map.setCenter({ lat: 13.7191758, lng: 100.5205329 });
    this.planPointer = {};

    this.mappingCMD={
        "TWCS Throttle (Vendor: 044f Product: b687)":{
          axes:[
            [this.cmd_uav.cam_yaw_left,this.cmd_uav.dummyCMD, this.cmd_uav.cam_yaw_right], //CH-0
            [this.cmd_uav.cam_pitch_up,this.cmd_uav.dummyCMD, this.cmd_uav.cam_pitch_down], //CH-1
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-2
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-3
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-4
            [this.cmd_uav.zoomInc,this.cmd_uav.dummyCMD,this.cmd_uav.zoomDec], //CH-5
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-6
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-7
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-8
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-9
          ],
          buttons:[
            this.cmd_uav.cam_yaw_origin, // BTN-0
            this.cmd_uav.start_record, // BTN-1
            this.cmd_uav.stop_record, // BTN-2
            this.cmd_uav.wide, // BTN-3
            this.cmd_uav.infra, // BTN-4
            this.cmd_uav.cam_pitch_origin, // BTN-5
            this.cmd_mode.EO_only, // BTN-6
            this.cmd_mode.EO_IRwhitehot, // BTN-7
            this.cmd_mode.EO_IRblackhot, // BTN-8
            this.cmd_mode.EO_IRpseudo, // BTN-9
            this.cmd_mode.IRwhitehot, // BTN-10
            this.cmd_mode.IRblackhot, // BTN-11
            this.cmd_mode.IRpseudo, // BTN-12
            this.cmd_mode.EO_only, // BTN-13
          ]
        },
        "T.16000M (Vendor: 044f Product: b10a)":{
          axes:[
            [this.cmd_uav.offboard_left, this.cmd_uav.dummyCMD,this.cmd_uav.offboard_right], //CH-0
            [this.cmd_uav.offboard_forward, this.cmd_uav.dummyCMD,this.cmd_uav.offboard_backward], //CH-1
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-2
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-3
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-4
            [this.cmd_uav.offboard_yaw_ccw,this.cmd_uav.dummyCMD,this.cmd_uav.offboard_yaw_cw], //CH-5
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-6
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-7
            [this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD,this.cmd_uav.dummyCMD], //CH-8
            [this.cmd_uav.offboard_upward,this.cmd_uav.dummyCMD,this.cmd_uav.offboard_downward], //CH-9 Special******
          ],
          buttons:[
            this.cmd_uav.dummyCMD, // BTN-0
            this.cmd_uav.dummyCMD, // BTN-1
            this.cmd_uav.dummyCMD, // BTN-2
            this.cmd_uav.dummyCMD, // BTN-3
            this.cmd_uav.abort, // BTN-4
            this.cmd_uav.resume, // BTN-5
            this.cmd_uav.pause, // BTN-6
            this.cmd_uav.dummyCMD, // BTN-7
            this.cmd_uav.dummyCMD, // BTN-8
            this.cmd_uav.dummyCMD, // BTN-9
            this.cmd_mode.OSD_all_on, // BTN-10
            this.cmd_mode.OSD_all_off, // BTN-11
            this.cmd_uav.nextwp, // BTN-12
            this.cmd_uav.dummyCMD, // BTN-13
            this.cmd_uav.dummyCMD, // BTN-14
            this.cmd_uav.dummyCMD, // BTN-15
          ]
        }
      };
    // const bounds = new window.google.maps.LatLngBounds(
    //   new window.google.maps.LatLng(12.993362, 101.442383),
    //   new window.google.maps.LatLng(12.99652, 101.449361)
    // );
    // let image = require("../assets/map/ptic.png");

    // class USGSOverlay extends window.google.maps.OverlayView {
    //   bounds;
    //   image;
    //   div;
    //   constructor(bounds, image) {
    //     super();
    //     this.bounds = bounds;
    //     this.image = image;
    //   }
    //   /**
    //    * onAdd is called when the map's panes are ready and the overlay has been
    //    * added to the map.
    //    */
    //   onAdd() {
    //     this.div = document.createElement("div");
    //     this.div.style.borderStyle = "none";
    //     this.div.style.borderWidth = "0px";
    //     this.div.style.position = "absolute";

    //     // Create the img element and attach it to the div.
    //     const img = document.createElement("img");

    //     img.src = this.image;
    //     img.style.width = "100%";
    //     img.style.height = "100%";
    //     img.style.position = "absolute";
    //     this.div.appendChild(img);

    //     // Add the element to the "overlayLayer" pane.
    //     const panes = this.getPanes();

    //     panes.overlayLayer.appendChild(this.div);
    //   }
    //   draw() {
    //     // We use the south-west and north-east
    //     // coordinates of the overlay to peg it to the correct position and size.
    //     // To do this, we need to retrieve the projection from the overlay.
    //     const overlayProjection = this.getProjection();
    //     // Retrieve the south-west and north-east coordinates of this overlay
    //     // in LatLngs and convert them to pixel coordinates.
    //     // We'll use these coordinates to resize the div.
    //     const sw = overlayProjection.fromLatLngToDivPixel(
    //       this.bounds.getSouthWest()
    //     );
    //     const ne = overlayProjection.fromLatLngToDivPixel(
    //       this.bounds.getNorthEast()
    //     );

    //     // Resize the image's div to fit the indicated dimensions.
    //     if (this.div) {
    //       this.div.style.left = sw.x + "px";
    //       this.div.style.top = ne.y + "px";
    //       this.div.style.width = ne.x - sw.x + "px";
    //       this.div.style.height = sw.y - ne.y + "px";
    //     }
    //   }
    //   /**
    //    * The onRemove() method will be called automatically from the API if
    //    * we ever set the overlay's map property to 'null'.
    //    */
    //   onRemove() {
    //     if (this.div) {
    //       this.div.parentNode.removeChild(this.div);
    //       delete this.div;
    //     }
    //   }
    //   /**
    //    *  Set the visibility to 'hidden' or 'visible'.
    //    */
    //   hide() {
    //     if (this.div) {
    //       this.div.style.visibility = "hidden";
    //     }
    //   }
    //   show() {
    //     if (this.div) {
    //       this.div.style.visibility = "visible";
    //     }
    //   }
    //   toggle() {
    //     if (this.div) {
    //       if (this.div.style.visibility === "hidden") {
    //         this.show();
    //       } else {
    //         this.hide();
    //       }
    //     }
    //   }
    //   toggleDOM(map) {
    //     if (this.getMap()) {
    //       this.setMap(null);
    //     } else {
    //       this.setMap(map);
    //     }
    //   }
    // }

    // const overlay = new USGSOverlay(bounds, image);

    // overlay.setMap(this.map);
    // var image = {
    //   url: require("../assets/uav2.png"),
    //   // url: require("../assets/drone2.png"),
    //   //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
    //   // This marker is 20 pixels wide by 32 pixels high.
    //   size: new window.google.maps.Size(100, 100),
    //   // The origin for this image is (0, 0).
    //   origin: new window.google.maps.Point(0, 0),
    //   // The anchor for this image is the base of the flagpole at (0, 32).
    //   anchor: new window.google.maps.Point(50, 50),
    //   rotation: 90,
    // };
    const svgMarker = {
      path: "M25 0 L0 50 L25 35 L50 50 Z",
      fillColor: "red",
      fillOpacity: 1,
      strokeWeight: 2,
      rotation: this.deviceStatus.system.data.heading.value,
      scale: 0.9,
      anchor: new window.google.maps.Point(25, 35),
    };
    this.marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(13.7191758, 100.5205329),
      // icon: image,
      icon: svgMarker,
      title: "ARV Drone",
      zIndex: 10000,
    });
    this.marker.setMap(this.map);
    this.flightPath = new window.google.maps.Polyline({
      path: [],
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.CenterChange();
    this.interValCenter = setInterval(this.CenterChange, 3000);
  },
  methods: {
    calculateFPS(){
      let currentObj = this;
      var last = this.lastCommand;
      currentObj.lastCommand = new Date().getTime();
      if(last !=0 )
      {
        let diff = this.lastCommand - last;
        currentObj.fps = 1000 / (diff);
        currentObj.calculatedFPS = true;
      }
    },
    enableJoystick(){
      this.joyStickControl = true;
      window.addEventListener("gamepadconnected", (event) => {
        console.log("Gamepad connected:", event.gamepad);
        this.updateGamepadData();
      });

      window.addEventListener("gamepaddisconnected", (event) => {
        console.log("Gamepad disconnected:", event.gamepad);
      });
      if (navigator.getGamepads) {
        this.updateGamepadData();
      }
    },
    disableJoystick(){
      this.joyStickControl = false;
    },
    updateGamepadData() {
      if(this.joyStickControl)
      {
        let currentObj = this;
        const gamepads = navigator.getGamepads();
        // let fps = 60;
        if(this.counterFilter == 0)
        {
          for (const gamepad of gamepads) {
            if (gamepad && this.mappingCMD[gamepad.id] !== undefined) {
              currentObj.joystick_info.previous[gamepad.id] = JSON.parse(JSON.stringify(currentObj.joystick_info.current[gamepad.id]));
              this.joystick_info.current[gamepad.id] = {
                id: gamepad.id,
                timestamp: gamepad.timestamp,
                axes: gamepad.axes.map((axis) => axis.toFixed(2)),
                buttons: gamepad.buttons.map((button) => button.pressed),
                connected: gamepad.connected,
              };
              if(gamepad.id != "T.16000M (Vendor: 044f Product: b10a)")
              {
                this.joystick_info.current[gamepad.id].axes.forEach((item, index) =>{
                var val = this.fillerStick(item);
                if(val != 1 && index < 9){
                  var cmd = this.mappingCMD[gamepad.id].axes[index][val];
                  if(!(cmd == this.cmd_uav.dummyCMD))
                  {this.sendCommand(this.mappingCMD[gamepad.id].axes[index][val]);}
                }
                });
              }
              else{
                var _up = gamepad.buttons[2].pressed;
                var _down = gamepad.buttons[3].pressed;
                var _enable = gamepad.buttons[0].pressed;
                if(_enable)
                {
                  // console.log('[',gamepad.buttons[2],' | ', gamepad.buttons[3],']')
                  if(_up && _down)
                  {
                    this.cmd_uav.velocity_body.data.data.down_m_s = 0.0;
                  }
                  else if(_up){
                    this.cmd_uav.velocity_body.data.data.down_m_s =  1.0;
                  }
                  else if(_down){
                    this.cmd_uav.velocity_body.data.data.down_m_s =  -1.0;
                  }
                  else{
                    this.cmd_uav.velocity_body.data.data.down_m_s = 0.0;
                  }

                  this.cmd_uav.velocity_body.data.data.forward_m_s = gamepad.axes[1];
                  this.cmd_uav.velocity_body.data.data.right_m_s =  gamepad.axes[0];
                  this.cmd_uav.velocity_body.data.data.yawspeed_deg_s =  gamepad.axes[5];
                  console.log(JSON.stringify(this.cmd_uav.velocity_body.data));
                  this.sendCommand(this.cmd_uav.velocity_body);
                }
              }
              this.joystick_info.current[gamepad.id].buttons.forEach((item,index) =>{
                var previous = currentObj.joystick_info.previous[gamepad.id].buttons[index];
                if(item != previous && item === true){
                  var cmd = this.mappingCMD[gamepad.id].buttons[index];
                  if(!(cmd == this.cmd_uav.dummyCMD))
                  {this.sendCommand(this.mappingCMD[gamepad.id].buttons[index]);}
                }
              })
            }
          }
        }
        currentObj.counterFilter += 1;
        if(this.counterFilter > (this.fps/10)){
          currentObj.counterFilter = 0;
        }
        requestAnimationFrame(this.updateGamepadData);
      }
    },
    fillerStick(value){
      let rv = (value > 0.3)?2:(value < -0.3)?0:1;
      return rv;
    },
    async getIndex() {
      let currentObj = this;
      let siteID = -1;
      this.customerAssets.devices.forEach((item, index) => {
        if (parseInt(item.aid) === parseInt(this.pageRanderInfo.deviceID)) {
          currentObj.pageRanderInfo.deviceIndex = index;
          siteID = parseInt(item.sid);
        }
      });
      if (siteID >= 0) {
        this.customerAssets.sites.forEach((item, index) => {
          if (parseInt(item.sid) === siteID) {
            currentObj.pageRanderInfo.siteIndex = index;
          }
        });
      }
    },
    async getKey(device_id) {
      let currentObj = this;
      var deviceKey = null;
      await this.axios
        .post(this.sioServer + "/auth/getkey?code=" + device_id, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          deviceKey = response.data.key;
          console.log(deviceKey);
          currentObj.pageRanderInfo.deviceKey = deviceKey;
          currentObj.$store.commit("setDeviceKey", deviceKey);
          // alert("Request Command : "+this.droneID);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          // alert(error);
        });
      return deviceKey;
    },
    async getCamData() {
      // console.log('-----PING-------');
      if (this.pageRanderInfo.deviceID >= 0) {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.boxid);
        console.log(
          "Call : ",
          this.webAPI +
            "/app/api/streamingmanagement?aid=" +
            this.pageRanderInfo.deviceID
        );
        await this.axios
          .get(
            this.webAPI +
              "/app/api/streaming?aid=" +
              this.pageRanderInfo.deviceID,
            {
              headers: {
                authorization: this.apiKey,
              },
            }
          )
          .then(function (response) {
            console.log("Cam Data : ", response);
            currentObj.camData = response.data;
          })
          .catch(function (error) {
            console.warn(error);
            swal.fire({
              icon: "error",
              title: "Oops... ",
              text: "Something went wrong! Please try again later",
            });
          });
      }
    },
    showDate(dd) {
      var date = new Date(dd);
      return date.toLocaleDateString("th-TH");
    },
    showTime(dd) {
      var date = new Date(dd);
      return date.toLocaleTimeString("th-TH", { hour12: false });
    },
    polylinedrow() {
      //alert('Drawing polyline ........')
      this.flightPath.setMap(null);
      this.flightPath = new window.google.maps.Polyline({
        path: this.path,
        geodesic: true,
        strokeColor: "PURPLE",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      this.flightPath.setMap(this.map);
      //alert('DONE ........')
    },
    locChange() {
      var icon = this.marker.getIcon();
      icon.rotation = this.deviceStatus.system.data.heading.value;
      this.marker.setIcon(icon);
      this.marker.setPosition(
        new window.google.maps.LatLng(
          this.deviceStatus.system.data.position_lat.value,
          this.deviceStatus.system.data.position_long.value
        )
      );

      this.marker.setMap(this.map);
      // this.changeRotate();
    },
    CenterChange() {
      // console.log(this.deviceStatus.system.data.position_lat.value);
      // console.log(this.deviceStatus.system.data.position_long.value);
      if (
        this.deviceStatus.system.data.position_lat.value &&
        this.deviceStatus.system.data.position_long.value
      ) {
        this.map.setCenter(
          new window.google.maps.LatLng(
            this.deviceStatus.system.data.position_lat.value,
            this.deviceStatus.system.data.position_long.value
          )
        );
        this.centered = true;
      }
      // this.polylinedrow();
    },
    getSiteName(sid) {
      var siteName = null;
      // console.log("sid:", sid);
      try {
        this.customerAssets.sites.forEach((obj) => {
          // console.log(obj.name);
          if (obj.sid === sid) {
            // console.log("name:", obj.name);
            siteName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return siteName;
    },
    getDeviceName(mid) {
      var assetName = null;
      try {
        this.customerAssets.devices.forEach((obj) => {
          // console.log(obj.name);
          if (obj.aid === mid) {
            assetName = obj.name;
            throw "Break";
          }
        });
      } catch (e) {
        if (e !== "Break") throw e;
      }
      return assetName;
    },
    onHIR_Cancel(data) {
      console.log(data);
      swal.close();
    },
    onHIR_Req(data) {
      var boxID = data.boxID;
      var droneID = data.droneID;
      var TaskID = data.taskID;
      // deepcode ignore PromiseNotCaughtNode: <please specify a reason of ignoring this>
      swal
        .fire({
          title: "Do you allow to continue task?",
          text: "This is Task Permission Approval! /n Task ID: " + data.taskID,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Approve",
          cancelButtonText: "Reject",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.hirAction(TaskID, boxID, droneID, true);

            swal.fire({
              title: "Approved!",
              text: "Your decision has been sent.",
              timer: 1500,
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === swal.DismissReason.cancel
          ) {
            this.hirAction(TaskID, boxID, droneID, false);
            swal.fire({
              title: "Rejected!",
              text: "Your decision has been sent.",
              timer: 1500,
              icon: "error",
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async hirAction(TaskID, boxID, DroneID, result) {
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      await this.control.emit("hir", {
        deviceID: this.userName,
        token: this.apiKey,
        msgTye: "hir_result",
        data: {
          msgFrom: this.userName,
          msgTo: boxID,
          command: {
            taskID: TaskID,
            boxID: boxID,
            droneID: DroneID,
            result: result,
          },
        },
      });
      //$("#hir").html('');
      // var txtResult = "Rejected";
      // if (result) {
      //   txtResult = "Approved";
      // }
      // await control.emit("task", {
      //   deviceID: deviceID,
      //   token: token,
      //   msgTye: "task_status",
      //   data: {
      //     msgFrom: "UI",
      //     taskID: TaskID,
      //     time: new Date().getTime(),
      //     status: "Permission " + txtResult,
      //   },
      // });
      // return false;
    },
    // pingtest() {
    //   this.ping.sendtime = new Date().getTime();
    //   this.$sio.emit("my_ping");
    // },
    clearMarker(item) {
      item.setMap(null);
    },
    onStatus_debug(data) {
      const d = new Date();
      let text = d.toTimeString();
      console.log(text + ":Receive Event Status");
      console.log(data);
    },
    onStatus(data) {
      // // console.log(data);
      // let currentObj = this;
      // currentObj.statusData = [];
      // currentObj.boxDataStatus = data;
      // for (const property in data.data) {
      //   this.createTable(data.data[property]);
      // }
      // console.log(data);
      let currentObj = this;
      currentObj.statusData = [];
      let existData = this.deviceStatus;
      // console.log('Exi : ',JSON.stringify(existData))
      let newData = data.data;
      // console.log('New : ',JSON.stringify(newData))
      currentObj.deviceStatus = Object.assign(existData, newData);
      if (
        currentObj.deviceStatus.system.data.position_lat.value != 0 &&
        currentObj.deviceStatus.system.data.position_long.value != 0
      ) {
        currentObj.path.push({
          lat: currentObj.deviceStatus.system.data.position_lat.value,
          lng: currentObj.deviceStatus.system.data.position_long.value,
        });
      }
      if (currentObj.path.length > 600) {
        currentObj.path.shift();
      }
      this.locChange();
      this.polylinedrow();
      if (this.deviceStatus.task.data.task_state.value === "REQUEST") {
        if (this.isRefreshID) {
          currentObj.camID = "0";
          currentObj.isRefreshID = false;
        }
        if (this.isLoadedMissionData) {
          currentObj.isLoadedMissionData = false;
          this.missionMarker.forEach(this.clearMarker);
          this.clearMarker(this.missionPath);
          this.missionMarker = [];
          this.missionPath = null;
        }
      } else {
        if (!this.isRefreshID) {
          currentObj.camID = this.camData[0].streamingKey + "&playOrder=webrtc";
          currentObj.isRefreshID = true;
        }
        if (!this.isLoadedMissionData) {
          console.log("Call Function Get Task.");
          this.getTaskInfo();
          currentObj.isLoadedMissionData = true;
        }
      }
    },
    async onConnect() {
      let currentObj = this;
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      if (this.socket.connected) {
        console.log("Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("join", {
          token: this.apiKey,
          room: currentObj.pageRanderInfo.deviceKey,
        });
        if (this.isInitialPage) {
          console.log("Initial Page");
          // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
          this.socket.emit("initdata", {
            token: this.apiKey,
            room: currentObj.pageRanderInfo.deviceKey,
          });
          currentObj.isInitialPage = false;
        }
      }
    },
    async onConnectControl() {
      let currentObj = this;
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      if (this.socket.connected) {
        console.log("Connected");
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.socket.emit("join", {
          token: this.apiKey,
          room: currentObj.pageRanderInfo.deviceKey,
        });
      }
    },
    cameraModeChange() {
      if (this.selectedPayloadMode === "Photo")
        this.sendCommand(this.cmd_uav.photo);
      else if (this.selectedPayloadMode === "Video")
        this.sendCommand(this.cmd_uav.video);
    },
    sendCommand(data) {
      if (this.control.connected) {
        console.log("Sending Command : ", data.name);
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.control.emit("command", {
          token: this.apiKey,
          deviceID: this.pageRanderInfo.deviceKey,
          cmd: {
            commandID: 1,
            taskID: 0,
            type: data.data.type,
            command: data.data.command,
            data: data.data.data,
          },
        });
      }
    },
    videoCommand(data) {
      var currentObj = this;
      currentObj.isRecording = !this.isRecording;
      this.sendCommand(data);
    },
    showControlPanel() {
      this.isPanelActive = !this.isPanelActive;
      if (this.statusStyle === 12) this.statusStyle = 4;
      else this.statusStyle = 12;
    },
    clearIntervalCommand() {
      clearInterval(this.intervalTimer);
      this.intervalCommand = null;
    },
    setIntervalCommand(data) {
      var currentObj = this;
      currentObj.intervalCommand = data;
      this.sendIntervalCommand();
      // deepcode ignore CodeInjection: <please specify a reason of ignoring this>
      currentObj.intervalTimer = setInterval(this.sendIntervalCommand, 300);
    },
    sendIntervalCommand() {
      if (this.control.connected && this.intervalCommand != null) {
        console.log("Sending Interval Command : ", this.intervalCommand.name);
        // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
        this.control.emit("command", {
          token: this.apiKey,
          deviceID: this.pageRanderInfo.deviceKey,
          cmd: {
            commandID: 1,
            taskID: 0,
            type: this.intervalCommand.data.type,
            command: this.intervalCommand.data.command,
            data: this.intervalCommand.data.data,
          },
        });
      }
    },
    receiveData() {
      let currentObj = this;
      currentObj.ping.receivetime = new Date().getTime();
      currentObj.ping.delay =
        currentObj.ping.receivetime - currentObj.ping.sendtime;
      // console.log('Latency : ', currentObj.ping.delay, ' ms');
    },
    logHandle(data) {
      let currentObj = this;
      // console.log(data.msg);
      var date = new Date(data.time);
      currentObj.logmsg.unshift({
        date: date.toLocaleDateString("th-TH"),
        time: date.toLocaleTimeString("th-TH", { hour12: false }),
        msg: data.msg,
      });
    },
    calTime() {
      var current_datetime = new Date();
      // var caldate =
      this.timeNow.date =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        current_datetime.getDate().toString().padStart(2, "0");
      this.timeNow.time =
        // var caltime =
        current_datetime.getHours().toString().padStart(2, "0") +
        ":" +
        current_datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        current_datetime.getSeconds().toString().padStart(2, "0");
      // return { date: caldate, time: caltime };
    },
    scrolldown() {
      this.$refs["card_body"].scrollTop =
        this.$refs["card_body"].scrollHeight + 100;
      // console.log(this.$refs['card_body'].scrollHeight);
    },
    async dronedata() {
      let currentObj = this;
      if (!this.isSimulate) {
        await this.axios
          .get(this.apiServer + "/drone/v210/status/sim1")
          .then(function (response) {
            // console.log('Drone res_code : ', response.status)
            if (response.status == 200) {
              currentObj.dronestatus = response.data;
            }
          })
          .catch(function (error) {
            // currentObj.output2 = error;
            console.warn(error);
          });
      }
    },
    async boxdata() {
      if (!this.isSimulate) {
        let currentObj = this;
        // console.log("call : " + this.apiServer + "/box/v2/status/" + this.boxID);
        await this.axios
          .get(this.apiServer + "/box/v210/status/base1") // + this.boxID)
          .then(function (response) {
            // console.log('BOX res_code : ', response.status)
            if (response.status == 200) {
              currentObj.boxtatus = response.data;
            }
          })
          .catch(function (error) {
            // currentObj.output2 = error;
            console.warn(error);
          });
        // this.caltime();
      }
    },
    async getDroneData() {
      if (this.droneid != "") {
        let currentObj = this;
        // console.log("Getting Data of Drone ID : ", this.droneid);
        // console.log("call : " + this.apiServer + "/drone/v2/status/" + this.datashowing.droneid);
        // this.ping.sendtime = new Date().getTime();
        await this.axios
          .get(this.realTimeServer + "/drone/v2/status/" + this.droneid)
          .then(function (response) {
            // currentObj.ping.receivetime = new Date().getTime();
            // currentObj.ping.delay = currentObj.ping.receivetime - currentObj.ping.sendtime
            if (response.status == 200) {
              currentObj.datashowing.dronedata = response.data;
              // console.log(currentObj.datashowing.dronedata);
              currentObj.datashowing.spinner = false;
            } else {
              currentObj.datashowing.spinner = true;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
            currentObj.datashowing.spinner = true;
          });
      }
    },
    async getBoxData() {
      // deepcode ignore AttrAccessOnNull: <please specify a reason of ignoring this>
      this.socket.emit("my_ping");
      this.ping.sendtime = new Date().getTime();
      // console.log('-----PING-------');
      if (this.boxid != "") {
        let currentObj = this;
        // console.log("Getting Data of Base ID : ", this.boxid);
        // console.log('Owner : ', currentObj.customer)
        await this.axios
          .get(this.realTimeServer + "/dbx/v2/status/" + this.boxid) // + currentObj.customer)
          .then(function (response) {
            if (response.status == 200) {
              // console.log(response.data);
              // currentObj.datashowing.boxdata = response.data;
              // console.log(currentObj.datashowing.boxdata);
              // currentObj.datashowing.spinner = false;
              // currentObj.states = response.data;
              // alert("Request Command : "+response.data.message);
            } else {
              currentObj.datashowing.spinner = true;
            }
          })
          .catch(function (error) {
            currentObj.output2 = error;
            currentObj.datashowing.spinner = true;
            // alert(error);
          });
      }
    },
    async getTaskInfo() {
      console.log("Get Task Data");
      console.log("Task ID : ", this.deviceStatus.task.data.task_id.value);
      if (this.deviceStatus.task.data.task_id.value != null) {
        // this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
        let currentObj = this;
        await this.axios
          // .get(this.apiServer + "/getmissionlist/" + this.siteName)
          .get(
            this.webAPI +
              "/app/api/task?taskID=" +
              this.deviceStatus.task.data.task_id.value,
            {
              headers: {
                authorization: this.apiKey,
              },
            }
          )
          .then(function (response) {
            currentObj.taskInfo = response.data;
            // this.onReset();
            // alert("Request Command : "+response.data.message);
          })
          .catch(function (error) {
            currentObj.output2 = error;
            console.log(error);
          });
        console.log(this.taskInfo);
        currentObj.missionDataID = this.taskInfo.tasks[0].missionDataID;
        await this.getMissionData();
        // this.missions.missions.forEach(this.buildMissionOption);
        // this.missionOption.push({ value: {}, text: "---Create New Mission---" });
      }
    },
    async getMissionData() {
      console.log("Get Mission Data");
      console.log("this.missionDataID : ", this.missionDataID);
      // this.dataTask.ETA = this.dataTask.ETD + this.dataTask.totalTime;
      let currentObj = this;
      await this.axios
        // .get(this.apiServer + "/getmissionlist/" + this.siteName)
        .get(this.webAPI + "/app/api/missiondata?id=" + this.missionDataID, {
          headers: {
            authorization: this.apiKey,
          },
        })
        .then(function (response) {
          currentObj.missionData = response.data;
          // this.onReset();
          // alert("Request Command : "+response.data.message);
        })
        .catch(function (error) {
          currentObj.output2 = error;
          console.log(error);
        });
      console.log(this.missionData);
      this.drawMapData();
      // this.missions.missions.forEach(this.buildMissionOption);
      // this.missionOption.push({ value: {}, text: "---Create New Mission---" });
    },
    drawMapData() {
      console.log("Drawing Data...");
      var paths = [];
      var index = 0;
      console.log(this.missionData);
      console.log(index, " of ", this.missionData.wpID.length);
      for (index = 0; index < this.missionData.wpID.length; index++) {
        console.log(index, " of ", this.missionData.wpID.length);
        console.log(this.missionData.wpCommand[index]);
        if (this.missionData.wpCommand[index] === 16) {
          paths.push(
            new window.google.maps.LatLng(
              this.missionData.wpLat[index],
              this.missionData.wpLng[index]
            )
          );
          this.missionMarker.push(
            this.displayMarker(
              this.missionData.wpLat[index],
              this.missionData.wpLng[index],
              index
            )
          );
        }
      }
      this.missionPath = this.polyPath(paths);
    },
    displayMarker(lat, lon, index) {
      var image = {
        url: require("../assets/point.png"),
        // url: require("../assets/ROI1.png"),
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(30, 30),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(15, 15),
      };
      return new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lon),
        icon: image,
        label: (index + 1).toString(),
        map: this.map,
        draggable: false,
        // animation: new window.google.maps.Animation.DROP,
        // zIndex: 0,
        title: "WP#" + (index + 1).toString(),
      });
    },
    polyPath(path) {
      const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const aIcon = {
        icon: lineSymbol,
        offset: "100%",
        repeat: "50px",
      };
      let icon = [aIcon];
      return new window.google.maps.Polyline({
        path: path,
        icons: icon,
        geodesic: true,
        strokeColor: "YELLOW",
        strokeOpacity: 0.7,
        strokeWeight: 1.0,
        map: this.map,
      });
    },
    enableControlFn() {
      this.UAVmenualControl = true;
      this.sendCommand(this.cmd_uav.offboard_enable);
      //Call Function Enable Control
    },
    disableControlFn() {
      this.UAVmenualControl = false;
      this.sendCommand(this.cmd_uav.offboard_disable);
      //Call Function Enable Control
    },
    clearIntervalUAV_ControlCommand() {
      clearInterval(this.intervalTimer);
      this.sendCommand(this.cmd_uav.velocity_body_stop);
      this.intervalCommand = null;
    },
  },
  beforeDestroy() {
    clearInterval(this.interValCenter);
    this.clearIntervalUAV_ControlCommand();
    this.socket.disconnect();
    this.control.disconnect();
    window.removeEventListener("gamepadconnected", (event) => {
      console.log("Gamepad connected:", event.gamepad);
      this.updateGamepadData();
    });

    window.removeEventListener("gamepaddisconnected", (event) => {
      console.log("Gamepad disconnected:", event.gamepad);
    });
  },
};
</script>
<style scoped>
h2 {
  font-size: 1.2em;
}
.card-data-value h4 {
  font-size: 0.95em;
  width: 95%;
  overflow: hidden;
  display: inline-block;
  /* text-align: center; */
}
.contentmain {
  margin-left: 2rem;
  margin-right: 2rem;
  height: calc(100vh - 100px);
}
.card-body {
  max-height: 70vh;
  overflow-y: auto;
}
main {
  margin-top: 0px;
  padding: 1.4rem 0rem 0rem 0rem;
  background: #f1f5f9;
  /* min-height: calc(100vh - 85px); */
}
.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  /* margin-top: 0rem; */
}
.card-single {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
  border-radius: 2px;
}
.card-single div:last-child span {
  color: var(--main-color);
  font-size: 3rem;
}
.card-single h1 {
  font-size: 1.2em;
}

.card-single div:first-child span {
  color: var(--text-grey);
  font-size: 00.8em;
}
.card-single:last-child {
  background: var(--main-color);
}
.card-single:last-child h1,
.card-single:last-child div:last-child span,
.card-single:last-child div:first-child span {
  color: #fff;
}
.recent-grid {
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 67% auto;
}
.card {
  background: #fff;
  border-radius: 5px;
}
.card-header,
.card-body {
  padding: 0.5rem 1rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1 solid #f0f0f0;
  margin: 0px;
  min-height: 3.5rem;
}
.card-header button {
  background: var(--main-color);
  border-radius: 10px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--main-color);
}
table {
  /* border-collapse: collapse;
   */
  position: relative;
  border-collapse: collapse;
}
thead th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}
thead tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
thead td {
  font-weight: 700;
}
td {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: #222;
}
tr td:last-child {
  display: flex;
  align-items: center;
}
td .status {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 1rem;
}
.red {
  background-color: red !important;
}
.yellow {
  background-color: yellow !important;
}
.green {
  background-color: green !important;
  color: white;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
}
.customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.7rem;
}
.info {
  display: flex;
  align-items: center;
}
.info img {
  border-radius: 50%;
  margin-right: 1rem;
}
.info h4 {
  font-size: 0.8rem;
  font-weight: 700;
  color: #222;
}
.info small {
  font-weight: 600;
  /* color: var(--text-grey); */
}
.contact span {
  font-size: 1.2rem;
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--main-color);
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  /* height: 50px; */
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background: #f0f0f0;
}

.search-wrapper span {
  display: inline-block;
  padding: 0rem 1rem;
  font-size: 1.5em;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section {
  width: calc(100vw - 350px - 80px - 4rem);
  /* width: 545px; */
  height: 700px;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: left;
  margin-left: 1rem;
  margin-right: 0px;
}

aside {
  width: 305px;
  height: 88vh;
  /* background: yellow; */
  /* text-align: center; */
  margin-top: 1rem;
  float: right;
  margin-left: 1rem;
  margin-right: 41px;
}
.clearfix {
  clear: both;
  margin-bottom: 20px;
}
footer {
  width: 880px;
  height: 50px;
  background: yellow;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.content1 {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  /* margin-bottom: 1rem; */
  border: #222 solid 1px;
  height: calc(50% - 1rem);
  /* width: 100%; */
}
.content1-upper {
  /* background-color: aquamarine; */
  background-color: whitesmoke;
  /* margin: 0 auto; */
  /* margin: 1rem; */
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  height: 50%;
  /* width: 100%; */
}
.main-container {
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: #222 solid 1px;
  position: relative;
  width: 100%;
  height: 88vh;
  display: block;
  position: relative;
  background: gray;
  overflow: hidden;
  /* width: 100%; */
}
.content1-upper h1,
.infor-status table,
.content1 h1 {
  font-size: 0.75rem;
}
.video-main {
  /* float: left; */
  width: 100%;
  /* width: calc(100vw - 350px - 80px - 4rem - 31%); */
  /* padding-right: 1rem; */
  /* height: calc(100vh - 72%); */
  height: 26.8vw;
  border-radius: 10px;
  overflow: hidden;
  max-height: 50vh;
  /* background-color: black; */
}
.map-panel {
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  /* height:300px; */
  height: 18.76vw;
}
.emergency-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn {
  width: 30%;
  height: 70px;
  /* border-radius: 25px 25px; */
  /* background-color: rgb(207, 0, 0); */
  font-size: 1rem;
  /* margin: 0 auto; */
  cursor: pointer;
  margin: 2px;
}
.cmdPanel-btn:hover {
  background-color: rgb(0, 255, 170);
  /* color: goldenrod; */
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.emergency-btn:hover {
  background-color: rgb(255, 0, 0);
  color: yellow;
  /* border-color: yellow; */
  border-width: 2px;
  font-weight: bold;
}
.log-msg {
  width: 100%;
  height: calc(100vh - 26.8vw - 130px);
  /* min-height: 350px;*/
  max-height: calc(100vh - 26.8vw - 130px);
  overflow: auto;
  /* overflow-y: auto; */
  /* background-color: var(--text-grey); */
  border-radius: 10px;
  font-size: 0.3rem;
}

.test {
  font-size: 0.5rem;
}
.control-status {
  min-height: 200px;
  max-height: 350px;
}
.device-status {
  float: left;
  width: calc(50% - 1rem);
  /* max-height: 250px;
  overflow-y: auto; */
}
.device-control {
  float: right;
  width: calc(50% - 1rem);
  height: 280px;
}
.tab-device-status {
  max-height: 250px;
  overflow-y: auto;
}
.cmd-btn {
  height: 50px;
  width: 150px;
  margin: 5px;
  padding: 5px;
  font-size: 0.75rem;
  border-radius: 10px;
  border-width: 1px;
}
.cmd-btn:hover {
  background-color: lightseagreen;
}
b-tab {
  font-size: 0.75rem;
}
.tab-class {
  font-size: 0.5rem;
}
.nav-tab-calss {
  font-size: 0.5rem;
}
.content-operation {
  width: 100%;
  height: 90vh;
  display: block;
  position: relative;
  background: gray;
}

.arm-status {
  text-shadow: 2px 2px #000000;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0 auto;
  padding: 0 auto;
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 100;
}
.miniMap {
  transition: top 300ms;
  border-radius: 200px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  /* background-color: rgba(22, 22, 22, 0.8); */
  width: 400px;
  height: 400px;
  z-index: 1;
  overflow: hidden;
  opacity: 0.3;
}
.zoombtn {
  position: absolute;
  left: 270px;
  bottom: 190px;
  z-index: 2;
  width: 140px;
}
.alarm {
  background-color: rgba(255, 0, 0, 0.8);
  /* color: yellow; */
}
.paraView {
  position: absolute;
  top: 500px;
  right: 10px;
  margin: 1rem;
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.6); */
  color: #f2f2f2;
  font-size: 1.8em;
  z-index: 100;
}
.sys-status {
  position: absolute;
  display: flexbox;
  align-content: center;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 10px;
  min-width: 20%;
  width: 75%;
  text-align: center;
  background: var(--main-color);
  color: var(--text-grey);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.status-card {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.infor-view {
  position: absolute;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  /* margin: 1rem; */
  align-content: flex-start;
  background: rgba(60, 60, 60, 0.74);
  color: #f2f2f2;
  /* font-size: 1.8em; */
  z-index: 100;
}
.icon-wrapper {
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-bottom: 10pex;
}
.icon-wrapper span {
  border-radius: 50%;
  margin-right: 0.5rem;
  font-size: 2rem;
}
.icon-wrapper h4 {
  margin-bottom: -2px;
}
.icon-wrapper small {
  display: inline-block;
  /* color: var(--text-grey); */
  margin-top: -10rem !important;
}
.control-command {
  /* position: absolute;
  bottom: 1rem;
  right: 1rem; */
  /* margin: 1rem; */
  align-content: flex-end;
  /* background: rgba(60, 60, 60, 0.74); */
  color: #f2f2f2;
  /* font-size: 1.8em; */
}
.test1 {
  /* margin: 2px; */
  margin: 0px;
  padding: 0px;
}
.left1-container {
  margin-top: 10px;
}
.center1-container {
  margin-top: 10px;
}

.card-data-value {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
}
.card-data-value2 {
  margin: 2px;
  padding: 2px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  text-align: center;
}
.card-data {
  margin: 2px;
  padding: 20px;
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
.card-frame {
  /* margin: 2px; */
  /* padding: 20px; */
  border: 1px rgb(192, 192, 192) solid;
  border-radius: 10px;
  background-color: whitesmoke;
  max-height: calc(100vh - 26.8vw - 105px);
  overflow: auto;
}
/* @-webkit-keyframes alarm { */
@keyframes alarm {
  0%,
  49% {
    background-color: whitesmoke;
    /* border: 1px solid red; */
  }
  50%,
  100% {
    background-color: yellow;

    /* border: 1px solid red; */
  }
}
.statusMini {
  max-width: 25vw;
}
.valueMaxCard {
  max-width: 100% !important;
  display: block;
}
.valveMinCard {
  max-width: 25% !important;
  display: inline;
}
.value-moving {
  /* margin: 2px; */
  /* background-color: yellow; */
  color: red;
  /* text-align: end; */
  /* padding-right: 5px; */
  /* padding: 2px; */
  border-bottom: 1px gray solid;
  -webkit-animation: alarm 1s infinite; /* Safari 4+ */
  -moz-animation: alarm 1s infinite; /* Fx 5+ */
  -o-animation: alarm 1s infinite; /* Opera 12+ */
  animation: alarm 1s infinite; /* IE 10+, Fx 29+ */
}
.uavControlPanel {
  font-size: 0.75rem;
  margin: 0px;
  padding: 0px;
  height: 35vh;
}
@media only screen and (max-width: 560px) {
  .map-panel {
    height: 25vh !important;
    /* display: none; */
  }
  .video-main {
    height: 25vh !important;
    /* display: none; */
  }
  .statusMini {
    max-width: 100vw !important;
  }
  .card-frame {
    max-height: auto;
    height: auto !important;
  }
  .uavControlPanel {
    height: auto !important;
  }
  .card-data,
  .card-body {
    max-height: 100% !important;
    height: auto !important;
  }
}

h3 {
  width: 100%;
  font-size: 1em;
  text-align: center;
}
.card-data-value-test {
  font-size: 0.75em;
}
.zoomControlBtn {
  display: block;
  width: 100%;
  margin: 10px 0 10px 0;
  font-size: 2em;
}
.menualPalyloadBtn {
  display: block;
  width: 40%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn2 {
  display: block;
  width: 25%;
  margin: 10px;
  font-size: 2em;
}
.menualPalyloadBtn3 {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  /* font-size: 2em; */
}
.tranfrom45 {
  transform: rotate(45deg);
}
.tranfrom-45 {
  transform: rotate(-45deg);
}
.noMargin {
  margin: 0 !important;
  width: 50%;
}
.shutterControlBtn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-width: 5px;
}
.shutterStopControlBtn {
  width: 70px;
  height: 70px;
  /* border-radius: 50%; */
}
.btn-layout {
  display: grid;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
  width: 100%;
}
.btn-mode {
  /* width: 100%; */
  height: 70px;
  padding: 20px;
  font-size: 12px;
  text-align: center;
}
</style>
